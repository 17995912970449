import { ref, Ref, watch, computed } from 'vue'
import RotasAPI from '@/apis/rota-architect/rotas'
import useToasts from '@/hooks/useToasts'
import parseErrorMap from '@/utils/parseErrorMap'

// Types
import { Shift } from '@/types/roster'

// Usage
// const {
//   dayIdToShiftsMap, // { day_id: [{shift_id}, {shift_id}] }
//   shiftMap, // { id: {shift} }
// } = useWeeklyAvailableShifts(rotaId)

/**
 * Fetches data from api and returns object maps for shift by id, and shift_ids array by day_id
 * 
 * @param rotaId string
 */
export default function useWeeklyAvailableShifts(rotaId: Ref<string>) {

  const dispatch = useToasts()

  // fetch weekly shifts info.
  const shifts = ref<Shift[]>([])
  const byDays = ref<{
    day_id: number;
    shifts: number[];
  }[]>([])

  const loading = ref(false)

  const fetchShifts = () => {
    if (loading.value) return

    loading.value = true

    RotasAPI.weeklyShifts(rotaId.value)
      .then((res) => {
        shifts.value = res.data.shifts
        byDays.value = res.data.by_day
      })
      .catch((err) => {
        dispatch.errorToast(parseErrorMap(err.response.data))
      })
      .finally(() => {
        loading.value = false
      })
  }

  watch(
    [rotaId],
    () => {
      fetchShifts()
    },
    {
      immediate: true,
    },
  )

  const shiftMap = computed(() => {
    if (!shifts.value.length) return null

    const map: {
      [key: string]: Shift;
    } = {}

    shifts.value.forEach(shift => {
      map[shift.id.toString()] = shift
    })

    return map
  })

  const dayIdToShiftsMap = computed(() => {
    if (!byDays.value.length) return null

    const map: {
      [key: string]: number[];
    } = {}

    byDays.value.forEach(day => {
      map[day.day_id.toString()] = day.shifts
    })

    return map
  })

  return {
    loading,
    shiftMap,
    dayIdToShiftsMap,
  }
}