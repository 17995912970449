
import { Shift } from '@/types/roster'
import { defineComponent, PropType } from 'vue'
import { pillThemeMap, gradeMap } from '@/constants/entries'
import { shiftTextContent, shiftPillType } from '@/hooks/useSingleUserRotaTable'

export default defineComponent({
  props: {
    options: {
      type: Array as PropType<Shift[]>,
      required: true,
    },
    slotId: {
      type: Number,
      required: true,
    },
  },

  emits: ['clicked', 'cancel'],

  setup (props) {
    
    return {
      gradeMap,
      shiftTextContent,
      themeClasses: pillThemeMap[shiftPillType(props.slotId)],
    }
  },
})
