
import { Entry, EntryStatus } from '@/types/roster'
import { computed, defineComponent, PropType } from 'vue'
import MasterEntryPill from './MasterEntryPill.vue'

export default defineComponent({
  components: {
    MasterEntryPill,
  },

  props: {
    entries: {
      type: Array as PropType<Entry[]>,
      required: true,
    },
  },

  emits: ['clicked'],

  setup (props) {
    
    const singleDeletingEntry = computed(() => {
      return props.entries.length === 1 && props.entries[0].status === EntryStatus.DELETING
    })

    return {
      singleDeletingEntry,
      createButtonClasses: 'w-7 h-7 border-2 border-gray-500 bg-blue-lightest rounded-full flex items-center justify-center text-gray-500 transition-colors duration-200 hover:bg-opacity-75 hover:text-grey hover:border-grey',
    }
  },
})
