
import { computed, defineComponent, PropType, ref, toRef } from 'vue'
import moment from 'moment'
import EntriesAPI from '@/apis/rota-architect/entries'
import parseErrorMap from '@/utils/parseErrorMap'

// Types
import { UserSuccessResponse } from '@/types/users'
import { AbsenceEntry, DateBasics, ShiftEntry, Updateable } from '@/types/roster'

// Hooks
import useToasts from '@/hooks/useToasts'
import useSuggestions from '@/hooks/useSuggestions'
import { 
  shiftTextContent,
  shiftPillType,
} from '@/hooks/useSingleUserRotaTable'

// Contstants
import { subGradeMap } from '@/constants/entries'

// Form
import { useForm } from 'vee-validate'
import * as yup from 'yup'

// Components
import EntryPill from '../EntryPill.vue'
import VUserRadioInput from '@/components/rota/form/VUserRadioInput.vue'
import UserSearchInput from '@/components/rota/form/UserSearchInput.vue'

export default defineComponent({
  components: {
    EntryPill,
    VUserRadioInput,
    UserSearchInput,
  },

  props: {
    date: {
      type: Object as PropType<DateBasics>,
      required: true,
    },
    currentEntry: {
      type: Object as PropType<ShiftEntry>,
      default: null,
    },
    annualLeaveEntries: {
      type: Array as PropType<AbsenceEntry[]>,
      required: true,
    },
  },

  emits: ['success', 'cancel'],

  setup (props, ctx) {
    const dispatch = useToasts()

    const formattedDate = computed(() => moment(props.date.date).format('DD MMM YYYY'))

    const formHeading = computed(() => 'Add a staff member to this locum shift')

    const createButtonText = computed(() => 'Update to draft')

    const shiftLabel = computed(() => shiftTextContent(props.currentEntry.shift, 'name'))

    const shiftType = computed(() => shiftPillType(props.currentEntry.shift.slot_id))

    const annualLeavers = computed(() => {
      if (!props.annualLeaveEntries.length) return '-'
      return props.annualLeaveEntries.map(a => a.user?.name ).join(' | ')
    })

    /**
     * Suggestions
     */
    const dateIdRef = toRef(props.date, 'id')
    const shiftIdRef = toRef(props.currentEntry.shift, 'id')

    const {
      loading: loadingSuggestions,
      suggestionOptions,
    } = useSuggestions(dateIdRef, shiftIdRef)

    /**
     * Search
     */
    const userOptions = ref<{
      id: string;
      label: string;
      grade: string;
    }[]>([])

    const updateSearchUsersOptions = (users: UserSuccessResponse[]) => {
      userOptions.value = users.slice(0, 5).map(user => ({
        id: user.id.toString(),
        label: user.name,
        grade: subGradeMap[user.sub_grade_id ?? 0],
      }))
    }

    /**
     * Form
     */
    const createSchema: yup.ObjectSchema<{ 
        user_id: string; 
        with_absence_id?: string;
    }> = yup.object({
      user_id: yup
        .string()
        .required('Please select an staff member.'),
      with_absence_id: yup
        .string(),
    }).defined()

    const { handleSubmit, values, errors } = useForm({
      validationSchema:  createSchema,
      initialValues: {
        user_id: '',
      },
    })

    const submitting = ref(false)
    const submissionError = ref('')

    const onSubmit = handleSubmit((values) => {
      if (submitting.value) return

      submitting.value = true
      submissionError.value = ''

      const payload: Updateable = {
        user_id: +values.user_id,
        with_absence_id: values.with_absence_id ? +values.with_absence_id : null,
      }

      EntriesAPI.updateLocum(props.currentEntry.entry_id, payload)
        .then((res) => {
          dispatch.successToast('Shift updated successfully.')

          const newEntries = 
            Array.isArray(res.data) 
              ? res.data // could be multi-entry if replacing an 'approved'
              : [res.data]

          ctx.emit('success', props.date.id, newEntries)
        })
        .catch((err) => {
          submissionError.value = parseErrorMap(err.response.data)
        })
        .finally(() => {
          submitting.value = false
        })
    });

    return {
      formattedDate,
      formHeading,
      annualLeavers,
      createButtonText,
      shiftLabel,
      shiftType,
      loadingSuggestions,
      suggestionOptions,
      updateSearchUsersOptions,
      userOptions,
      values,
      formErrors: errors,
      onSubmit,
      submitting,
      submissionError,
    }
  },
})
