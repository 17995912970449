<template>
  <header class="bg-blue-medium flex items-center justify-center text-white text-xl">
    <span class="py-3 w-full text-center">
      {{ formattedDate }}
    </span>
  </header>

  <div
    v-if="shift"
    class="w-full text-center bg-gray-200 py-6 px-4 transition-all ease-in-out duration-500"
    :class="[withAlterations ? 'max-h-60' : 'max-h-shift']"
  >
    <h3 class="text-blue-dark font-bold uppercase text-lg">
      Selected
    </h3>
    <div class="flex justify-center">
      <span class="relative">
        <EntryPill
          :content="shiftLabel"
          :type="shiftType"
          :grade="shift.grade_id"
          size="md"
        />
        <span class="absolute top-1/2 transform -translate-y-1/2 -right-8">
          <BaseIconButton
            :color="withAlterations ? 'text-red-medium hover:text-red-dark' : 'text-blue-medium hover:text-blue-dark'"
            @click="toggleWithAlterations"
          >
            <CloseIcon v-if="withAlterations" />
            <EditIcon v-else />
          </BaseIconButton>
        </span>
      </span>
    </div>

    <transition name="fade">
      <div
        v-if="withAlterations"
        class="mt-6"
      >
        <header class="w-full mb-1">
          Altering shift times:  
        </header>

        <section class="flex justify-center">
          <span class="block w-1/3 mx-2">
            <VShiftDurationInput
              label="Shift Length"
              :value="values.alternate_hours"
              name="alternate_hours"
              :disabled="submitting"
            />
          </span>
          <span class="block w-1/3 mx-2">
            <VTimeInput
              label="Start Time"
              :value="values.alternate_start"
              name="alternate_start"
              :disabled="submitting"
            />
          </span>
          <span class="block w-1/3 mx-2">
            <TimeInput
              label="End Time"
              v-model="endTime"
              :disabled="true"
            />
          </span>
        </section>
      </div>
    </transition>
  </div>

  <div class="bg-blue-dark flex items-center justify-center w-100 py-3">
    <p class="text-white">
      Is this a locum shift?
    </p>
    <span class="flex items-center ml-6">
      <span 
        class="mr-2"
        :class="[values.is_locum ? 'text-gray-800' : 'text-white font-bold']"
      >No</span>
      <VToggle
        name="is_locum"
        :readonly="submitting"
        :value="values.is_locum"
      />
      <span 
        class="ml-2"
        :class="[values.is_locum ? 'text-white font-bold' : 'text-gray-800']"
      >Yes</span>
    </span>
  </div>

  <form
    @submit="onSubmit"
    class="p-4"
  >
    <h2 class="text-center text-lg font-bold pt-2">
      {{ formHeading }}
    </h2>
    
    <fieldset
      class="mb-8"
      :disabled="submitting || loadingSuggestions"
    >
      <p class="text-center mb-4">
        Recommendations:
      </p>
    
      <div
        v-if="loadingSuggestions"
        class="flex flex-wrap justify-between w-10/12 mx-auto mb-6"
      >
        <div
          v-for="(i) in Array(5)"
          :key="i"
          class="bg-blue-lightest pulse w-49pc h-7 mb-1"
        />
      </div>

      <div
        v-else-if="suggestionOptions.length"
        class="flex flex-wrap justify-between w-10/12 mx-auto mb-6"
      >
        <VUserRadioInput
          v-for="opt in suggestionOptions"
          :key="opt.id"
          name="user_id"
          :label="opt.label"
          :value="opt.id"
          :grade-abbreviation="opt.grade"
        />
      </div>

      <div
        v-else
        class="mb-6 text-center text-blue-medium text-sm"
      >
        <p>
          No recommendations found.
        </p>
      </div>

      <UserSearchInput
        :rota-id="1"
        :readonly="submitting"
        @users="updateSearchUsersOptions"
      >
        <template #label>
          <p class="text-center">
            Manually add staff:
          </p>
          <p class="text-sm italic text-blue-medium text-center w-1/2 mx-auto mb-4">
            You will be able to add any staff member using this search, regardless of their Grade.
          </p>
        </template>
      </UserSearchInput>

      <div
        v-if="userOptions.length"
        class="flex flex-wrap justify-between w-10/12 mx-auto mb-6 mt-10"
      >
        <VUserRadioInput
          v-for="opt in userOptions"
          :key="opt.id"
          name="user_id"
          :label="opt.label"
          :value="opt.id"
          :grade-abbreviation="opt.grade"
        />
      </div>

      <div class="text-center mb-4 text-blue-medium">
        <p class="text-sm">
          Staff currently on annual leave:
        </p>
        <p>
          {{ annualLeavers }}
        </p>
      </div>

      <div
        v-if="values.is_locum && values.user_id"
        class="bg-gray-100 px-6 pt-3"
      >
        <VSelectInput
          :options="[
            { value: '2', label: 'Annual Leave' },
            { value: '8', label: 'TOIL' },
          ]"
          name="with_absence_id"
          label="With Absence type"
        />
      </div>
    </fieldset>

    <!-- <pre>
      {{ values }}
      {{ formErrors }}
    </pre> -->

    <div
      v-if="formErrors.user_id"
      class="text-center text-red-medium my-4"
    >
      {{ formErrors.user_id }}
    </div>

    <BaseErrorMessage
      v-if="submissionError"
      extend-wrapper-classes="my-3 md:my-6"
    >
      {{ submissionError }}
    </BaseErrorMessage>

    <div class="flex flex-col md:flex-row justify-end items-center gap-3 mt-4 pt-4 border-t-2 border-gray-200">
      <BaseButton
        size="md"
        theme="base"
        @click="$emit('cancel')"
        :disabled="submitting"
      >
        Cancel
      </BaseButton>

      <!-- Submit -->
      <BaseButton
        theme="success"
        size="md"
        type="submit"
        :disabled="loadingSuggestions"
        :working="submitting"
      >
        {{ createButtonText }}
      </BaseButton>
    </div>
  </form>
</template>

<script lang="ts">
import { computed, defineComponent, PropType, ref, toRef } from 'vue'
import moment from 'moment'
import EntriesAPI from '@/apis/rota-architect/entries'
import parseErrorMap from '@/utils/parseErrorMap'

// Types
import { UserSuccessResponse } from '@/types/users'
import { AbsenceEntry, DateBasics, Draftable, Shift } from '@/types/roster'

// Hooks
import useToasts from '@/hooks/useToasts'
import useSuggestions from '@/hooks/useSuggestions'
import { 
  shiftTextContent,
  shiftPillType,
} from '@/hooks/useSingleUserRotaTable'

// Contstants
import ROTA_ID from '@/constants/rota'
import { subGradeMap } from '@/constants/entries'

// Form
import { useForm } from 'vee-validate'
import * as yup from 'yup'

// Components
import EntryPill from '../EntryPill.vue'
import VTimeInput from '@/components/base/form/VTimeInput.vue'
import VToggle from '@/components/rota/form/VToggle.vue'
import VUserRadioInput from '@/components/rota/form/VUserRadioInput.vue'
import VSelectInput from '@/components/base/form/VSelectInput.vue'
import UserSearchInput from '@/components/rota/form/UserSearchInput.vue'
import VShiftDurationInput from '@/components/rota/form/VShiftDurationInput.vue'
import EditIcon from '@/components/icons/EditIcon.vue'
import CloseIcon from '@/components/icons/toast/CloseIcon.vue'

export default defineComponent({
  components: {
    VToggle,
    EntryPill,
    VUserRadioInput,
    UserSearchInput,
    VSelectInput,
    VTimeInput,
    VShiftDurationInput,
    EditIcon,
    CloseIcon,
  },

  props: {
    date: {
      type: Object as PropType<DateBasics>,
      required: true,
    },
    shift: {
      type: Object as PropType<Shift>,
      default: null,
    },
    annualLeaveEntries: {
      type: Array as PropType<AbsenceEntry[]>,
      required: true,
    },
  },

  emits: ['success', 'cancel'],

  setup (props, ctx) {
    const dispatch = useToasts()

    const formattedDate = computed(() => moment(props.date.date).format('DD MMM YYYY'))

    const formHeading = computed(() => 'Add a staff member to this shift')

    const createButtonText = computed(() => 'Create draft')

    const shiftLabel = computed(() => shiftTextContent(props.shift, 'name'))

    const shiftType = computed(() => shiftPillType(props.shift.slot_id))

    const annualLeavers = computed(() => {
      if (!props.annualLeaveEntries.length) return '-'
      return props.annualLeaveEntries.map(a => a.user?.name ).join(' | ')
    })

    /**
     * Suggestions
     */
    const dateIdRef = toRef(props.date, 'id')
    const shiftIdRef = toRef(props.shift, 'id')

    const {
      loading: loadingSuggestions,
      suggestionOptions,
    } = useSuggestions(dateIdRef, shiftIdRef)

    /**
     * Search
     */
    const userOptions = ref<{
      id: string;
      label: string;
      grade: string;
    }[]>([])

    const updateSearchUsersOptions = (users: UserSuccessResponse[]) => {
      userOptions.value = users.slice(0, 5).map(user => ({
        id: user.id.toString(),
        label: user.name,
        grade: subGradeMap[user.sub_grade_id ?? 0],
      }))
    }

    /**
     * Form
     */
    const createSchema: yup.ObjectSchema<{ 
        user_id?: string; 
        with_absence_id?: string;
        is_locum: boolean;
        alternate_start?: string;
        alternate_hours?: number;
    }> = yup.object({
      is_locum: yup
        .boolean(),
      user_id: yup
        .string()
        .when('is_locum', {
          is: false,
          then: yup.string().required('Please select an staff member.'),
        }),
      with_absence_id: yup
        .string()
        .when(['is_locum', 'user_id'], {
          is: (isLocum: boolean, userId: string) => isLocum && !!userId,
          then: yup.string(),
        }),
      alternate_start: yup
        .string(),
      alternate_hours: yup
        .number()
        .min(1, 'Length must be greater than 1 hour.'),
    }).defined()

    const { handleSubmit, values, errors } = useForm({
      validationSchema:  createSchema,
      initialValues: {
        is_locum: false,
        user_id: '',
        alternate_start: '00:00:00',
        alternate_hours: 0,
      },
    })

    // not input by user, so not part of form validation.
    const endTime = computed(() => {
      if (!values.alternate_start) return '00:00:00'

      return moment(values.alternate_start, 'HH:mm:ss').add(values.alternate_hours, 'hours').format('HH:mm:ss')
    })

    const submitting = ref(false)
    const submissionError = ref('')
    const withAlterations = ref(false)

    const toggleWithAlterations = () => {
      withAlterations.value = !withAlterations.value
    }

    const onSubmit = handleSubmit((values) => {
      if (submitting.value) return

      submitting.value = true
      submissionError.value = ''

      const payload: Draftable = {
        rosterable_type: 'shifts',
        rosterable_id: props.shift.id,
        user_id: values.user_id ? +values.user_id : null,
        date_id: props.date.id,
        is_locum: values.is_locum,
        rota_id: ROTA_ID,
        with_absence_id: values.with_absence_id ? +values.with_absence_id : null,
        alternate_start: values.alternate_start ? values.alternate_start : null,
        alternate_end: values.alternate_start ? endTime.value : null,
        alternate_hours: values.alternate_start && values.alternate_hours ? values.alternate_hours : null,
      }

      EntriesAPI.createDraft(payload)
        .then((res) => {
          dispatch.successToast('Entry added successfully.')

          const newEntries = 
            Array.isArray(res.data) 
              ? res.data // could be multi-entry if replacing an 'approved'
              : [res.data]

          ctx.emit('success', props.date.id, newEntries)
        })
        .catch((err) => {
          submissionError.value = parseErrorMap(err.response.data)
        })
        .finally(() => {
          submitting.value = false
        })
    });

    return {
      formattedDate,
      formHeading,
      annualLeavers,
      createButtonText,
      shiftLabel,
      shiftType,
      loadingSuggestions,
      suggestionOptions,
      updateSearchUsersOptions,
      userOptions,
      values,
      formErrors: errors,
      onSubmit,
      submitting,
      submissionError,
      // alterations
      withAlterations,
      toggleWithAlterations,
      endTime,
    }
  },
})
</script>
