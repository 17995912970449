<template>
  <button 
    class="w-6-1/2 h-6-1/2 xxl:w-8 xxl:h-8 border rounded-full flex items-center justify-center text-center text-white text-xs has-tooltip hover:bg-opacity-75 duration-200 transition-colors ease-in focus:outline-none active:outline-none"
    :class="statusClasses"
    @click.stop="$emit('clicked')"
  >
    <slot />

    <div
      class="tooltip bg-grey px-2 py-3 border-gray-500 text-white shadow-md rounded-md w-120 p-2"
      :class="tooltipClass"
    >
      <h3 class="font-bold mb-1 text-sm">
        Staffing Requirements | {{ staffingData.name }}
      </h3>
      <div class="flex border-t-2 border-white">
        <div class="w-32 flex-shrink-0 text-right border-r-2 border-white">
          <h4 class="border-b-2 border-white py-1 pr-2 text-sm">
            Overview
          </h4>
          <p class="font-bold pr-2 pt-2 mb-1 italic text-xs">
            Total Numbers
          </p>
          <p class="text-xs leading-snug pr-2">
            Minimum: {{ staffingData.min_count }}<br>
            Preferred: {{ staffingData.pref_count }}<br>
            Maximum: {{ staffingData.max_count ?? '-' }}<br>
            Current: {{ staffingData.current_count }}
          </p>
        </div>
        <div class="w-full text-left">
          <h4 class="border-b-2 py-1 border-white pl-2 text-sm">
            Detailed Breakdown
          </h4>
          <div
            v-if="hasAdditional"
            class="flex flex-col flex-wrap max-h-48 py-2"
          >
            <p
              v-for="item in staffingData.additional"
              :key="item.label"
              class="mb-1 w-49pc block px-2 text-xs"
            >
              <span class="block italic font-bold mb-1">{{ item.label }}</span>
              <span
                v-if="item.min !== undefined"
                class="block"
              >
                Minimum: {{ item.min }}
              </span>
              <span
                v-if="item.preferred !== undefined"
                class="block"
              >
                Preferred: {{ item.preferred }}
              </span>
              <span
                v-if="item.max !== undefined"
                class="block"
              >
                Max: {{ item.max }}
              </span>
              Current: {{ item.current }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </button>
</template>

<script lang="ts">
import { computed, defineComponent, PropType } from 'vue'

// Types
import { StaffingGroupCountMap } from '@/hooks/useStaffingCounter'

export enum TooltipLocation {
  BELOW_RIGHT = 'below-right',
  BELOW_LEFT = 'below-left',
  ABOVE_RIGHT = 'above-right',
  ABOVE_LEFT = 'above-left',
}

export default defineComponent({
  props: {
    staffingGroupId: {
      type: Number,
      required: true,
    },
    countMap: {
      type: Object as PropType<StaffingGroupCountMap>,
      required: true,
    },
    tooltipLocation: {
      type: String,
      validator: (val: TooltipLocation) => [
          TooltipLocation.BELOW_RIGHT, 
          TooltipLocation.BELOW_LEFT,
          TooltipLocation.ABOVE_RIGHT,
          TooltipLocation.ABOVE_LEFT,
        ].includes(val),
      required: true,
    },
  },

  emits: ['clicked'],

  setup (props) {

    const staffingData = computed(() => props.countMap[props.staffingGroupId] ?? null)

    const statusClasses = computed(() => {
      
      if (staffingData.value === null) return ''
      const additional = staffingData.value.additional

      // if global requirement are below minimum
      if (staffingData.value.current_count < staffingData.value.min_count) {
        return 'bg-red-dark'
      }

      // if any additional requirements are below minimum
      if (staffingData.value.has_additional) {
        for (const item in additional) {
          if ('min' in additional[item] && (additional[item].current < additional[item].min!)) {
            return 'bg-red-dark'
          }
        }
      }

      // if global requirements are below preferred
      if (staffingData.value.current_count < staffingData.value.pref_count) {
        return 'bg-blue-dark'
      }

      // if any additional requirements are below preferred
      if (staffingData.value.has_additional) {
        for (const item in additional) {
          if ('preferred' in additional[item] && (additional[item].current < additional[item].preferred!)) {
            return 'bg-blue-dark'
          }
        }
      }

      // if global requirements are above max
      if (staffingData.value.max_count && staffingData.value.current_count > staffingData.value.max_count) {
        return 'bg-orange-medium'
      }

      // if any additional requirements are above max
      if (staffingData.value.has_additional) {
        for (const item in additional) {
          if ('max' in additional[item] && (additional[item].current > additional[item].preferred!)) {
            return 'bg-orange-medium'
          }
        }
      }

      return 'bg-green-dark'
    })

    const hasAdditional = computed(() => staffingData.value && staffingData.value.has_additional)

    const tooltipClass = computed(() => {
      switch (props.tooltipLocation) {
        case TooltipLocation.BELOW_RIGHT:
          return 'tooltip-below-right'
        case TooltipLocation.BELOW_LEFT:
          return 'tooltip-below-left'
        case TooltipLocation.ABOVE_RIGHT:
          return 'tooltip-above-right'
        case TooltipLocation.ABOVE_LEFT:
          return 'tooltip-above-left'
      }
    })

    return {
      statusClasses,
      staffingData,
      hasAdditional,
      tooltipClass,
    }
  },
})
</script>

<style scoped>
.tooltip {
  z-index: 1;
  transition: 200ms opacity 0.5s ease-in;
}

.tooltip-below-right {
  top: 120%;
  left: 0%;
}

.tooltip-below-left {
  top: 120%;
  right: 0%;
}

.tooltip-above-right {
  bottom: 120%;
  left: 0%;
}

.tooltip-above-left {
  bottom: 120%;
  right: 0%;
}

.tooltip-below-right::after {
  bottom: 100%;  /* At the top of the tooltip */
  left: 1rem;
  margin-left: -5px;
  border-width: 5px;
  border-color: transparent transparent #3F4040 transparent;
}

.tooltip-below-left::after {
  bottom: 100%;  /* At the top of the tooltip */
  right: 1rem;
  margin-right: -5px;
  border-width: 5px;
  border-color: transparent transparent #3F4040 transparent;
}

.tooltip-above-right::after {
  top: 100%;  /* At the bottom of the tooltip */
  left: 1rem;
  margin-left: -5px;
  border-width: 5px;
  border-color: #3F4040 transparent transparent  transparent;
}

.tooltip-above-left::after {
  top: 100%;  /* At the bottom of the tooltip */
  right: 1rem;
  margin-right: -5px;
  border-width: 5px;
  border-color: #3F4040 transparent transparent  transparent;
}

.tooltip::after {
  content: " ";
  position: absolute;
  border-style: solid;
}
</style>