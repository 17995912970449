<template>
  <button
    class="flex items-center active:outline-none focus:outline-none transition-colors ease-in duration-300 mb-1 hover:bg-opacity-75"
    :class="[
      sizeClasses, 
      themeClasses, 
      tooltip ? 'has-tooltip' : '',
    ]"
    @click="$emit('clicked')"
  >
    <span class="text-white rounded-l-full h-5 w-10 text-xs flex items-center justify-center">
      {{ gradeOutput }}
    </span>
    <transition name="fade-slow">
      <div
        v-if="isDeleting"
        class="flex bg-red-dark w-5 h-5 items-center justify-center bg-opacity-75 flex-shrink-0"
      >
        <span class="flex w-3 h-3 text-white">
          <DeletingStatusIcon />
        </span>
      </div>
      <div
        v-else-if="isDraft"
        class="bg-gray-600 flex w-5 h-5 items-center justify-center bg-opacity-75 flex-shrink-0"
      >
        <span class="flex w-3 h-3 text-white">
          <DraftStatusIcon />
        </span>
      </div>
    </transition>
    <span 
      class="truncate w-full flex-1 flex items-center text-left pl-1 bg-white h-5"
      :class="{
        'text-blue-medium': !isDraft && !isAbsence,
        'text-gray-600': isDraft || isAbsence,
        'rounded-r-full': !isLocum,
      }"
    >
      {{ entry.user?.name ?? 'Unfilled' }}
    </span>
    <div
      v-if="isLocum"
      class="flex bg-blue-light w-5 h-5 items-center justify-center bg-opacity-75 rounded-r-full flex-shrink-0"
      :class="isWithAbsence ? 'bg-entry-absence' : 'bg-blue-light'"
    >
      <span class="flex w-3 h-3 text-white">
        <LocumIcon />
      </span>
    </div>
    <BaseToolTip
      v-if="tooltip"
      size="md"
    >
      {{ entryInfo }}

      <template v-if="withAbsence">
        <br>
        <span>(with {{ withAbsence }})</span>
      </template>
    </BaseToolTip>
  </button>
</template>

<script lang="ts">
import { StringMap } from '@/types/base'
import { computed, defineComponent, PropType } from 'vue'
import { Entry, EntryPillType, EntryStatus } from '@/types/roster'
import moment from 'moment'
import { absencePillType } from '@/hooks/useSingleUserRotaTable'
import { gradeMap, subGradeMap } from '@/constants/entries' 

import DeletingStatusIcon from '@/components/icons/rota/DeletingStatusIcon.vue'
import DraftStatusIcon from '@/components/icons/rota/DraftStatusIcon.vue'
import LocumIcon from '@/components/icons/rota/LocumIcon.vue'

const sizeMap: StringMap = {
  'full': 'h-6 w-49pc border-2 text-xs rounded-full',
}

const shiftStatusMap: StringMap = {
  [EntryStatus.DRAFT]: 'border-gray-600 bg-gray-600',
  [EntryStatus.PENDING]: 'border-blue-medium bg-blue-medium',
  [EntryStatus.APPROVED]: 'border-blue-medium bg-blue-medium',
  [EntryStatus.DELETING]: 'border-blue-medium bg-blue-medium',
}

const absenceTypeMap: StringMap = {
  [EntryPillType.ABSENCE]: 'border-entry-absence bg-entry-absence',
  [EntryPillType.STUDY_LEAVE]: 'border-entry-study bg-entry-study',
  [EntryPillType.SICKNESS]: 'border-entry-sickness bg-entry-sickness',
}

export default defineComponent({
  components: {
    DeletingStatusIcon,
    DraftStatusIcon,
    LocumIcon,
  },

  props: {
    entry: {
      type: Object as PropType<Entry>,
      required: true,
    },
    size: {
      type: String,
      validator: (val: string) => ['full'].includes(val),
      default: 'full',
    },
    tooltip: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['clicked'],

  setup (props) {
    const gradeOutput = computed(() => {
      if (props.entry.user) {
        return subGradeMap[props.entry.user?.sub_grade_id]
      }

      if (props.entry.type === 'shifts') { // locum shift
        return gradeMap[props.entry.shift.grade_id]
      }

      return ''
    })

    const isDeleting = computed(() => props.entry.status === EntryStatus.DELETING)

    const isDraft = computed(() => props.entry.status === EntryStatus.DRAFT)

    const isLocum = computed(() => props.entry.is_locum)
    
    const isAbsence = computed(() => props.entry.type === 'absences')

    const isWithAbsence = computed(() => !!props.entry.with_absence_id)

    const withAbsence = computed(() => props.entry.type === 'shifts' ? props.entry?.with_absence ?? '' : '')

    const entryInfo = computed(() => {
      if (props.entry.type === 'shifts') {
        const { start, end, name } = props.entry.shift 
        return `${name} (${moment(start, 'HH:mm:ss').format('HH:mm')}-${moment(end, 'HH:mm:ss').format('HH:mm')})`
      }

      if (props.entry.type === 'absences') {
        return props.entry.absence.label
      }
      
    })

    const themeClasses = computed(() => {
      if (props.entry.type === 'shifts') return shiftStatusMap[props.entry.status]

      if (props.entry.type === 'absences') {
        return absenceTypeMap[absencePillType(props.entry.absence.id)]
      }
    })

    return {
      sizeClasses: sizeMap[props.size],
      themeClasses,
      gradeOutput,
      isDeleting,
      isDraft,
      isLocum,
      isAbsence,
      entryInfo,
      isWithAbsence,
      withAbsence,
    }
  },
})
</script>
