<template>
  <div
    class="p-1"
    :class="extendWrapperClasses"
  >
    <header
      v-if="loading"
      class="bg-blue-lightest pulse mb-2 h-12"
    />
    <header
      v-else
      class="flex items-center text-white px-2 py-1 mb-2 h-12"
      :class="[isAbsences ? 'bg-red-medium' : 'bg-blue-medium']"
    >
      <div
        class="border-2 border-white rounded-full h-8 w-8 mr-3 flex items-center justify-center text-sm"
        :class="[isAbsences ? 'bg-red-dark' : 'bg-blue-dark']"
      >
        {{ totalStaff }}
      </div>
      <h3 clss="italics">
        {{ title }}
      </h3>

      <div class="ml-auto text-right">
        <h3
          v-if="primaryNames"
          class="font-bold -mb-1"
        >
          {{ primaryNames }}
        </h3>
        <h4 
          class="text-sm h-5"
        >
          {{ consultantNames }}
        </h4>
      </div>
    </header>

    <div
      v-if="loading"
      class="flex flex-wrap justify-between px-1"
    >
      <slot
        v-for="i in Array(3)"
        :key="i"
        name="entries-skeleton"
      />
    </div>
    <div
      v-else
      class="flex flex-wrap justify-between px-1"
    >
      <slot name="entries" />
      <slot />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    extendWrapperClasses: {
      type: String,
      default: '',
    },
    loading: {
      type: Boolean,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    primaryNames: {
      type: String,
      default: '',
    },
    consultantNames: {
      type: String,
      default: '',
    },
    totalStaff: {
      type: Number,
      default: 0,
    },
    isAbsences: {
      type: Boolean,
      default: false,
    },
  },

  setup () {
    

    return {}
  },
})
</script>
