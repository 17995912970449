<template>
  <div class="hidden xl:grid grid-container min-h-88vh max-w-screen">
    <div
      v-if="!!$slots.header"
      class="item-header"
    >
      <slot name="header" />
    </div>

    <section class="item-main overflow-x-auto">
      <slot name="main" />
    </section>

    <section class="item-sidebar">
      <slot name="sidebar" />
    </section>
  </div>

  <div class="xl:hidden flex items-center min-h-88vh justify-center text-center">
    <p>
      This page can only be viewed on larger screens.
    </p>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  setup () {
    

    return {}
  },
})
</script>

<style lang="scss" scoped>
.item-header {
  grid-area: header;
}

.item-main {
  grid-area: main;
  align-self: stretch;
}

.item-sidebar {
  grid-area: sidebar;
}


.grid-container {
  grid-template-columns: 1fr;
  grid-template-areas:
    "";

  @media only screen and (min-width: 1280px) {
    grid-template-columns: 3fr 1fr;
    grid-template-rows: auto 1fr;
    column-gap: 0.5rem;
    row-gap: 0.5rem;
    grid-template-areas:
      "header sidebar"
      "main sidebar";
  }
}
</style>