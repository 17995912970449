
import { computed, defineComponent, PropType, ref, toRef } from 'vue'
import moment from 'moment'
import EntriesAPI from '@/apis/rota-architect/entries'
import parseErrorMap from '@/utils/parseErrorMap'

// Types
import { UserSuccessResponse } from '@/types/users'
import { AbsenceEntry, DateBasics, Draftable, Shift } from '@/types/roster'

// Hooks
import useToasts from '@/hooks/useToasts'
import useSuggestions from '@/hooks/useSuggestions'
import { 
  shiftTextContent,
  shiftPillType,
} from '@/hooks/useSingleUserRotaTable'

// Contstants
import ROTA_ID from '@/constants/rota'
import { subGradeMap } from '@/constants/entries'

// Form
import { useForm } from 'vee-validate'
import * as yup from 'yup'

// Components
import EntryPill from '../EntryPill.vue'
import VTimeInput from '@/components/base/form/VTimeInput.vue'
import VToggle from '@/components/rota/form/VToggle.vue'
import VUserRadioInput from '@/components/rota/form/VUserRadioInput.vue'
import VSelectInput from '@/components/base/form/VSelectInput.vue'
import UserSearchInput from '@/components/rota/form/UserSearchInput.vue'
import VShiftDurationInput from '@/components/rota/form/VShiftDurationInput.vue'
import EditIcon from '@/components/icons/EditIcon.vue'
import CloseIcon from '@/components/icons/toast/CloseIcon.vue'

export default defineComponent({
  components: {
    VToggle,
    EntryPill,
    VUserRadioInput,
    UserSearchInput,
    VSelectInput,
    VTimeInput,
    VShiftDurationInput,
    EditIcon,
    CloseIcon,
  },

  props: {
    date: {
      type: Object as PropType<DateBasics>,
      required: true,
    },
    shift: {
      type: Object as PropType<Shift>,
      default: null,
    },
    annualLeaveEntries: {
      type: Array as PropType<AbsenceEntry[]>,
      required: true,
    },
  },

  emits: ['success', 'cancel'],

  setup (props, ctx) {
    const dispatch = useToasts()

    const formattedDate = computed(() => moment(props.date.date).format('DD MMM YYYY'))

    const formHeading = computed(() => 'Add a staff member to this shift')

    const createButtonText = computed(() => 'Create draft')

    const shiftLabel = computed(() => shiftTextContent(props.shift, 'name'))

    const shiftType = computed(() => shiftPillType(props.shift.slot_id))

    const annualLeavers = computed(() => {
      if (!props.annualLeaveEntries.length) return '-'
      return props.annualLeaveEntries.map(a => a.user?.name ).join(' | ')
    })

    /**
     * Suggestions
     */
    const dateIdRef = toRef(props.date, 'id')
    const shiftIdRef = toRef(props.shift, 'id')

    const {
      loading: loadingSuggestions,
      suggestionOptions,
    } = useSuggestions(dateIdRef, shiftIdRef)

    /**
     * Search
     */
    const userOptions = ref<{
      id: string;
      label: string;
      grade: string;
    }[]>([])

    const updateSearchUsersOptions = (users: UserSuccessResponse[]) => {
      userOptions.value = users.slice(0, 5).map(user => ({
        id: user.id.toString(),
        label: user.name,
        grade: subGradeMap[user.sub_grade_id ?? 0],
      }))
    }

    /**
     * Form
     */
    const createSchema: yup.ObjectSchema<{ 
        user_id?: string; 
        with_absence_id?: string;
        is_locum: boolean;
        alternate_start?: string;
        alternate_hours?: number;
    }> = yup.object({
      is_locum: yup
        .boolean(),
      user_id: yup
        .string()
        .when('is_locum', {
          is: false,
          then: yup.string().required('Please select an staff member.'),
        }),
      with_absence_id: yup
        .string()
        .when(['is_locum', 'user_id'], {
          is: (isLocum: boolean, userId: string) => isLocum && !!userId,
          then: yup.string(),
        }),
      alternate_start: yup
        .string(),
      alternate_hours: yup
        .number()
        .min(1, 'Length must be greater than 1 hour.'),
    }).defined()

    const { handleSubmit, values, errors } = useForm({
      validationSchema:  createSchema,
      initialValues: {
        is_locum: false,
        user_id: '',
        alternate_start: '00:00:00',
        alternate_hours: 0,
      },
    })

    // not input by user, so not part of form validation.
    const endTime = computed(() => {
      if (!values.alternate_start) return '00:00:00'

      return moment(values.alternate_start, 'HH:mm:ss').add(values.alternate_hours, 'hours').format('HH:mm:ss')
    })

    const submitting = ref(false)
    const submissionError = ref('')
    const withAlterations = ref(false)

    const toggleWithAlterations = () => {
      withAlterations.value = !withAlterations.value
    }

    const onSubmit = handleSubmit((values) => {
      if (submitting.value) return

      submitting.value = true
      submissionError.value = ''

      const payload: Draftable = {
        rosterable_type: 'shifts',
        rosterable_id: props.shift.id,
        user_id: values.user_id ? +values.user_id : null,
        date_id: props.date.id,
        is_locum: values.is_locum,
        rota_id: ROTA_ID,
        with_absence_id: values.with_absence_id ? +values.with_absence_id : null,
        alternate_start: values.alternate_start ? values.alternate_start : null,
        alternate_end: values.alternate_start ? endTime.value : null,
        alternate_hours: values.alternate_start && values.alternate_hours ? values.alternate_hours : null,
      }

      EntriesAPI.createDraft(payload)
        .then((res) => {
          dispatch.successToast('Entry added successfully.')

          const newEntries = 
            Array.isArray(res.data) 
              ? res.data // could be multi-entry if replacing an 'approved'
              : [res.data]

          ctx.emit('success', props.date.id, newEntries)
        })
        .catch((err) => {
          submissionError.value = parseErrorMap(err.response.data)
        })
        .finally(() => {
          submitting.value = false
        })
    });

    return {
      formattedDate,
      formHeading,
      annualLeavers,
      createButtonText,
      shiftLabel,
      shiftType,
      loadingSuggestions,
      suggestionOptions,
      updateSearchUsersOptions,
      userOptions,
      values,
      formErrors: errors,
      onSubmit,
      submitting,
      submissionError,
      // alterations
      withAlterations,
      toggleWithAlterations,
      endTime,
    }
  },
})
