<template>
  <header class="bg-blue-medium flex items-center justify-center text-white text-xl">
    <span class="py-3 w-full text-center">
      {{ formattedDate }}
    </span>
  </header>

  <div class="w-full text-center bg-gray-200 py-6 px-4">
    <h3 class="text-blue-dark font-bold uppercase text-lg">
      PENDING LOCUM SHIFT
    </h3>
    <div class="flex justify-center">
      <EntryPill
        :content="shiftLabel"
        :type="shiftType"
        :grade="currentEntry.grade_id"
        size="md"
      />
    </div>
  </div>

  <form
    @submit="onSubmit"
    class="p-4"
  >
    <h2 class="text-center text-lg font-bold pt-2">
      {{ formHeading }}
    </h2>
    
    <fieldset
      class="mb-8"
      :disabled="submitting || loadingSuggestions"
    >
      <p class="text-center mb-4">
        Recommendations:
      </p>
    
      <div
        v-if="loadingSuggestions"
        class="flex flex-wrap justify-between w-10/12 mx-auto mb-6"
      >
        <div
          v-for="(i) in Array(5)"
          :key="i"
          class="bg-blue-lightest pulse w-49pc h-7 mb-1"
        />
      </div>

      <div
        v-else-if="suggestionOptions.length"
        class="flex flex-wrap justify-between w-10/12 mx-auto mb-6"
      >
        <VUserRadioInput
          v-for="opt in suggestionOptions"
          :key="opt.id"
          name="user_id"
          :label="opt.label"
          :value="opt.id"
          :grade-abbreviation="opt.grade"
        />
      </div>

      <div
        v-else
        class="mb-6 text-center text-blue-medium text-sm"
      >
        <p>
          No recommendations found.
        </p>
      </div>

      <UserSearchInput
        :rota-id="1"
        :readonly="submitting"
        @users="updateSearchUsersOptions"
      >
        <template #label>
          <p class="text-center">
            Manually add staff:
          </p>
          <p class="text-sm italic text-blue-medium text-center w-1/2 mx-auto mb-4">
            You will be able to add any staff member using this search, regardless of their Grade.
          </p>
        </template>
      </UserSearchInput>

      <div
        v-if="userOptions.length"
        class="flex flex-wrap justify-between w-10/12 mx-auto mb-6 mt-10"
      >
        <VUserRadioInput
          v-for="opt in userOptions"
          :key="opt.id"
          name="user_id"
          :label="opt.label"
          :value="opt.id"
          :grade-abbreviation="opt.grade"
        />
      </div>

      <div class="text-center mb-4 text-blue-medium">
        <p class="text-sm">
          Staff currently on annual leave:
        </p>
        <p>
          {{ annualLeavers }}
        </p>
      </div>

      <div class="bg-gray-100 px-6 pt-3">
        <VSelectInput
          :options="[
            { value: '2', label: 'Annual Leave' },
            { value: '8', label: 'TOIL' },
          ]"
          name="with_absence_id"
          label="With Absence type"
        />
      </div>
    </fieldset>

    <!-- <pre>
      {{ values }}
      {{ formErrors }}
    </pre> -->

    <div
      v-if="formErrors.user_id"
      class="text-center text-red-medium my-4"
    >
      {{ formErrors.user_id }}
    </div>

    <BaseErrorMessage
      v-if="submissionError"
      extend-wrapper-classes="mb-3 md:mb-6"
    >
      {{ submissionError }}
    </BaseErrorMessage>

    <div class="flex flex-col md:flex-row justify-end items-center gap-3 mt-4 pt-4 border-t-2 border-gray-200">
      <BaseButton
        size="md"
        theme="base"
        @click="$emit('cancel')"
        :disabled="submitting"
      >
        Cancel
      </BaseButton>

      <!-- Submit -->
      <BaseButton
        theme="success"
        size="md"
        type="submit"
        :disabled="loadingSuggestions"
        :working="submitting"
      >
        {{ createButtonText }}
      </BaseButton>
    </div>
  </form>
</template>

<script lang="ts">
import { computed, defineComponent, PropType, ref, toRef } from 'vue'
import moment from 'moment'
import EntriesAPI from '@/apis/rota-architect/entries'
import parseErrorMap from '@/utils/parseErrorMap'

// Types
import { UserSuccessResponse } from '@/types/users'
import { AbsenceEntry, DateBasics, ShiftEntry, Updateable } from '@/types/roster'

// Hooks
import useToasts from '@/hooks/useToasts'
import useSuggestions from '@/hooks/useSuggestions'
import { 
  shiftTextContent,
  shiftPillType,
} from '@/hooks/useSingleUserRotaTable'

// Contstants
import { subGradeMap } from '@/constants/entries'

// Form
import { useForm } from 'vee-validate'
import * as yup from 'yup'

// Components
import EntryPill from '../EntryPill.vue'
import VUserRadioInput from '@/components/rota/form/VUserRadioInput.vue'
import UserSearchInput from '@/components/rota/form/UserSearchInput.vue'

export default defineComponent({
  components: {
    EntryPill,
    VUserRadioInput,
    UserSearchInput,
  },

  props: {
    date: {
      type: Object as PropType<DateBasics>,
      required: true,
    },
    currentEntry: {
      type: Object as PropType<ShiftEntry>,
      default: null,
    },
    annualLeaveEntries: {
      type: Array as PropType<AbsenceEntry[]>,
      required: true,
    },
  },

  emits: ['success', 'cancel'],

  setup (props, ctx) {
    const dispatch = useToasts()

    const formattedDate = computed(() => moment(props.date.date).format('DD MMM YYYY'))

    const formHeading = computed(() => 'Add a staff member to this locum shift')

    const createButtonText = computed(() => 'Update to draft')

    const shiftLabel = computed(() => shiftTextContent(props.currentEntry.shift, 'name'))

    const shiftType = computed(() => shiftPillType(props.currentEntry.shift.slot_id))

    const annualLeavers = computed(() => {
      if (!props.annualLeaveEntries.length) return '-'
      return props.annualLeaveEntries.map(a => a.user?.name ).join(' | ')
    })

    /**
     * Suggestions
     */
    const dateIdRef = toRef(props.date, 'id')
    const shiftIdRef = toRef(props.currentEntry.shift, 'id')

    const {
      loading: loadingSuggestions,
      suggestionOptions,
    } = useSuggestions(dateIdRef, shiftIdRef)

    /**
     * Search
     */
    const userOptions = ref<{
      id: string;
      label: string;
      grade: string;
    }[]>([])

    const updateSearchUsersOptions = (users: UserSuccessResponse[]) => {
      userOptions.value = users.slice(0, 5).map(user => ({
        id: user.id.toString(),
        label: user.name,
        grade: subGradeMap[user.sub_grade_id ?? 0],
      }))
    }

    /**
     * Form
     */
    const createSchema: yup.ObjectSchema<{ 
        user_id: string; 
        with_absence_id?: string;
    }> = yup.object({
      user_id: yup
        .string()
        .required('Please select an staff member.'),
      with_absence_id: yup
        .string(),
    }).defined()

    const { handleSubmit, values, errors } = useForm({
      validationSchema:  createSchema,
      initialValues: {
        user_id: '',
      },
    })

    const submitting = ref(false)
    const submissionError = ref('')

    const onSubmit = handleSubmit((values) => {
      if (submitting.value) return

      submitting.value = true
      submissionError.value = ''

      const payload: Updateable = {
        user_id: +values.user_id,
        with_absence_id: values.with_absence_id ? +values.with_absence_id : null,
      }

      EntriesAPI.updateLocum(props.currentEntry.entry_id, payload)
        .then((res) => {
          dispatch.successToast('Shift updated successfully.')

          const newEntries = 
            Array.isArray(res.data) 
              ? res.data // could be multi-entry if replacing an 'approved'
              : [res.data]

          ctx.emit('success', props.date.id, newEntries)
        })
        .catch((err) => {
          submissionError.value = parseErrorMap(err.response.data)
        })
        .finally(() => {
          submitting.value = false
        })
    });

    return {
      formattedDate,
      formHeading,
      annualLeavers,
      createButtonText,
      shiftLabel,
      shiftType,
      loadingSuggestions,
      suggestionOptions,
      updateSearchUsersOptions,
      userOptions,
      values,
      formErrors: errors,
      onSubmit,
      submitting,
      submissionError,
    }
  },
})
</script>
