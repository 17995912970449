import { ref, Ref, watch, computed } from 'vue'
import RotasAPI from '@/apis/rota-architect/rotas'
import useToasts from '@/hooks/useToasts'
import parseErrorMap from '@/utils/parseErrorMap'

// Types
import { StaffingGroup } from '@/types/staffingGroup'

// Usage
// const {
//   dayIdToStaffingGroupsMap, // { day_id: [{sg_id}, {sg_id}] }
//   staffingGroupMap, // { id: {staffing_group} }
// } = useWeeklyAvailableShifts(rotaId)

/**
 * Fetches data from api and returns object maps for staffing group by id, 
 * and staffing_group_ids array by day_id
 * 
 * @param rotaId string
 */
export default function useWeeklyRotaRequirements(rotaId: Ref<string>) {

  const dispatch = useToasts()

  // fetch weekly shifts info.
  const staffingGroups = ref<StaffingGroup[]>([])
  const byDays = ref<{
    day_id: number;
    staffing_groups: number[];
  }[]>([])

  const loading = ref(false)

  const fetchRequirements = () => {
    if (loading.value) return

    loading.value = true

    RotasAPI.weeklyRequirements(rotaId.value)
      .then((res) => {
        staffingGroups.value = res.data.staffing_groups
        byDays.value = res.data.by_day
      })
      .catch((err) => {
        dispatch.errorToast(parseErrorMap(err.response.data))
      })
      .finally(() => {
        loading.value = false
      })
  }

  watch(
    [rotaId],
    () => {
      fetchRequirements()
    },
    {
      immediate: true,
    },
  )

  const staffingGroupMap = computed(() => {
    if (!staffingGroups.value.length) return null

    const map: {
      [key: string]: StaffingGroup;
    } = {}

    staffingGroups.value.forEach(staffingGroup => {
      map[staffingGroup.id.toString()] = staffingGroup
    })

    return map
  })

  const dayIdToStaffingGroupsMap = computed(() => {
    if (!byDays.value.length) return null

    const map: {
      [key: string]: number[];
    } = {}

    byDays.value.forEach(day => {
      map[day.day_id.toString()] = day.staffing_groups
    })

    return map
  })

  return {
    loading,
    staffingGroupMap,
    dayIdToStaffingGroupsMap,
  }
}