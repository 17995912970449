
import { computed, defineComponent, PropType } from 'vue'

// Types
import { StaffingGroupCountMap } from '@/hooks/useStaffingCounter'

export enum TooltipLocation {
  BELOW_RIGHT = 'below-right',
  BELOW_LEFT = 'below-left',
  ABOVE_RIGHT = 'above-right',
  ABOVE_LEFT = 'above-left',
}

export default defineComponent({
  props: {
    staffingGroupId: {
      type: Number,
      required: true,
    },
    countMap: {
      type: Object as PropType<StaffingGroupCountMap>,
      required: true,
    },
    tooltipLocation: {
      type: String,
      validator: (val: TooltipLocation) => [
          TooltipLocation.BELOW_RIGHT, 
          TooltipLocation.BELOW_LEFT,
          TooltipLocation.ABOVE_RIGHT,
          TooltipLocation.ABOVE_LEFT,
        ].includes(val),
      required: true,
    },
  },

  emits: ['clicked'],

  setup (props) {

    const staffingData = computed(() => props.countMap[props.staffingGroupId] ?? null)

    const statusClasses = computed(() => {
      
      if (staffingData.value === null) return ''
      const additional = staffingData.value.additional

      // if global requirement are below minimum
      if (staffingData.value.current_count < staffingData.value.min_count) {
        return 'bg-red-dark'
      }

      // if any additional requirements are below minimum
      if (staffingData.value.has_additional) {
        for (const item in additional) {
          if ('min' in additional[item] && (additional[item].current < additional[item].min!)) {
            return 'bg-red-dark'
          }
        }
      }

      // if global requirements are below preferred
      if (staffingData.value.current_count < staffingData.value.pref_count) {
        return 'bg-blue-dark'
      }

      // if any additional requirements are below preferred
      if (staffingData.value.has_additional) {
        for (const item in additional) {
          if ('preferred' in additional[item] && (additional[item].current < additional[item].preferred!)) {
            return 'bg-blue-dark'
          }
        }
      }

      // if global requirements are above max
      if (staffingData.value.max_count && staffingData.value.current_count > staffingData.value.max_count) {
        return 'bg-orange-medium'
      }

      // if any additional requirements are above max
      if (staffingData.value.has_additional) {
        for (const item in additional) {
          if ('max' in additional[item] && (additional[item].current > additional[item].preferred!)) {
            return 'bg-orange-medium'
          }
        }
      }

      return 'bg-green-dark'
    })

    const hasAdditional = computed(() => staffingData.value && staffingData.value.has_additional)

    const tooltipClass = computed(() => {
      switch (props.tooltipLocation) {
        case TooltipLocation.BELOW_RIGHT:
          return 'tooltip-below-right'
        case TooltipLocation.BELOW_LEFT:
          return 'tooltip-below-left'
        case TooltipLocation.ABOVE_RIGHT:
          return 'tooltip-above-right'
        case TooltipLocation.ABOVE_LEFT:
          return 'tooltip-above-left'
      }
    })

    return {
      statusClasses,
      staffingData,
      hasAdditional,
      tooltipClass,
    }
  },
})
