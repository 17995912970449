import { ref, Ref, watch } from 'vue'
import UsersAPI from '@/apis/rota-architect/users'
import useToasts from '@/hooks/useToasts'
import parseErrorMap from '@/utils/parseErrorMap'

// Types
import { UserBasics } from '@/types/users'


export default function useGradeUsers(
  rotaId: Ref<number>,
  gradeId: Ref<number | null>,
) {

  const dispatch = useToasts()

  // fetch master rota info.
  const users = ref<UserBasics[]>([])
  const loadingUsers = ref(false)

  const fetchGradeUsers = () => {
    if (loadingUsers.value) return

    loadingUsers.value = true

    const queryString = `?rota=${rotaId.value}&grade=${gradeId.value}&lean=1&pageless=1&order_by_sub_grade=asc`

    UsersAPI.all(queryString)
      .then((res) => {
        users.value = res.data
      })
      .catch((err) => {
        dispatch.errorToast(parseErrorMap(err.response.data))
      })
      .finally(() => {
        loadingUsers.value = false
      })
  }

  watch(
    [rotaId, gradeId],
    () => {
      fetchGradeUsers()
    },
    {
      immediate: true,
    },
  )

  return {
    loadingUsers,
    users,
  }
}