<template>
  <label
    class="block w-49pc mb-1 cursor-pointer"
  >
    <div 
      class="border-2 flex items-center text-center h-7 rounded-full"
      :class="[value === inputValue ? 'bg-blue-medium border-blue-medium': 'bg-gray-500 border-gray-500']"
    >
      <div class="rounded-l-full w-12 text-white flex-shrink-0 text-sm flex items-center justify-center h-6">
        {{ gradeAbbreviation }}
      </div>
      <div 
        class="rounded-r-full w-full flex items-center justify-center h-6 bg-white text-sm"
        :class="[value === inputValue ? 'text-blue-medium': 'text-gray-500']"
      >
        <span class="truncate w-9/12 mx-auto">
          {{ label }}
        </span>
      </div>
    </div>
    
    <input
      type="radio"
      v-bind="$attrs"
      :name="name"
      class="hidden"
      :value="value"
      @input="handleChange"
      @blur="handleBlur"
    >
  </label>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { useField } from 'vee-validate'

export default defineComponent({
  inheritAttrs: false,
  
  props: {
    name: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      default: '',
    },
    value: {
      type: String,
      default: '',
    },
    gradeAbbreviation: {
      type: String,
      required: true,
    },
  },

  setup (props) {
    const { 
      value: inputValue, 
      handleBlur,
      handleChange,
    } = useField(
      props.name, 
      '', // no validator as using form-level-validation
      {
        valueProp: props.value,
      },
    )

    return {
      handleBlur,
      handleChange,
      inputValue,
    }
  },
})
</script>
