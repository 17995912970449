
import { defineComponent } from 'vue'
import { useField } from 'vee-validate'

export default defineComponent({
  inheritAttrs: false,
  
  props: {
    name: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      default: '',
    },
    value: {
      type: String,
      default: '',
    },
    gradeAbbreviation: {
      type: String,
      required: true,
    },
  },

  setup (props) {
    const { 
      value: inputValue, 
      handleBlur,
      handleChange,
    } = useField(
      props.name, 
      '', // no validator as using form-level-validation
      {
        valueProp: props.value,
      },
    )

    return {
      handleBlur,
      handleChange,
      inputValue,
    }
  },
})
