<template>
  <div
    class="absolute top-8 w-64 sm:w-80 right-0 rounded-md shadow-lg transition ease-out bg-white z-10"
    @mouseleave="$emit('leaving')"
    :class="[
      isVisible
        ? 'opacity-100 scale-100 duration-200'
        : 'opacity-0 scale-95 pointer-events-none duration-100'
    ]"
  >
    <div class="p-4">
      <h2 class="uppercase mb-2 text-gray-900">
        ENTRY STATUS KEY
      </h2>
      <ul>
        <li class="flex text-gray-900 mb-4">
          <div class="w-8 h-8 rounded-full bg-red-dark mr-3 flex p-2 flex-shrink-0 text-white">
            <DeletingStatusIcon />
          </div>
          <section>
            <p>
              Deleting
            </p>
            <p class="text-xs text-gray-900 mb-2">
              An entry that appears in the Live rota, but is flagged as 'deleting'. Approving will delete permanently from the rota.
            </p>
            <p class="text-xs text-gray-900">
              Usually a 'deleting' entry has a 'draft' entry waiting to replace it.
            </p>
          </section>
        </li>
        <li class="flex text-gray-900 mb-4">
          <div class="w-8 h-8 rounded-full bg-gray-600 mr-3 p-2 bg-opacity-50 flex-shrink-0 flex text-white">
            <DraftStatusIcon />
          </div>
          <section>
            <p>
              Draft
            </p>
            <p class="text-xs text-gray-900">
              An entry that has not been approved yet. 'Draft' entries do not appear in the Live rota, but do appear on the Ghost rota.
            </p>
          </section>
        </li>
        <li class="flex text-gray-900 mb-4">
          <div class="w-8 h-8 rounded-full bg-blue-light mr-3 flex p-2 flex-shrink-0 text-white">
            <LocumIcon />
          </div>
          <section>
            <p>
              Locum Shift
            </p>
            <p class="text-xs text-gray-900 mb-2">
              A shift entry where the staff is contracted at a fixed hourly rate.
            </p>
          </section>
        </li>
        <li class="flex text-gray-900 mb-4">
          <div class="w-8 h-8 bg-entry-absence rounded-full mr-3 flex p-2 flex-shrink-0 text-white">
            <LocumIcon />
          </div>
          <section>
            <p>
              Locum Shift with Absence
            </p>
            <p class="text-xs text-gray-900 mb-2">
              A locum shift where the staff member is also taking annual leave/TOIl under their employment contract.
            </p>
          </section>
        </li>
      </ul>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import DeletingStatusIcon from '@/components/icons/rota/DeletingStatusIcon.vue'
import DraftStatusIcon from '@/components/icons/rota/DraftStatusIcon.vue'
import LocumIcon from '@/components/icons/rota/LocumIcon.vue'

export default defineComponent({
  components: {
    DeletingStatusIcon,
    DraftStatusIcon,
    LocumIcon,
  },

  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['leaving'],

  setup () {
    

    return {}
  },
})
</script>
