<template>
  <button
    class="flex w-full items-center hover:bg-opacity-50 active:outline-none focus:outline-none relative transition-colors ease-in duration-300 mb-1 h-6  border-2 text-xs rounded-full border-gray-500 bg-gray-500"
    @click="$emit('clicked')"
  >
    <span class="text-white rounded-l-full h-full w-20 text-xs flex items-center justify-center " />
    <div class="bg-white flex items-center h-full w-full rounded-r-full">
      <span class="w-3 h-3 flex text-blue-medium ml-2">
        <BareAdditionIcon />
      </span>
    </div>
  </button>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import BareAdditionIcon from '@/components/icons/BareAdditionIcon.vue'

export default defineComponent({
  components: {
    BareAdditionIcon,
  },

  emits: ['clicked'],

  setup () {
    

    return {}
  },
})
</script>
