import { ref, Ref, watch } from 'vue'
import RotasAPI from '@/apis/rota-architect/rotas'
import useToasts from '@/hooks/useToasts'
import parseErrorMap from '@/utils/parseErrorMap'
import isEqual from 'lodash/isEqual'

// Types
import { RosterDate, ShiftEntry } from '@/types/roster'

export default function useMasterRota(
  rotaId: Ref<string>,
  startDate: Ref<string>, 
  endDate: Ref<string>,
  gradeId: Ref<number | null>,
) {

  const dispatch = useToasts()

  // fetch master rota info.
  const rota = ref<RosterDate<ShiftEntry>[]>([])
  const loadingRota = ref(false)

  const fetchRota = () => {
    if (loadingRota.value) return

    loadingRota.value = true

    return RotasAPI.master(rotaId.value, startDate.value, endDate.value, gradeId.value)
      .then((res) => {
        rota.value = res.data
      })
      .catch((err) => {
        dispatch.errorToast(parseErrorMap(err.response.data))
      })
      .finally(() => {
        loadingRota.value = false
      })
  }

  watch(
    [rotaId, startDate, endDate, gradeId],
    (newValues, oldValues) => {
      if (!isEqual(newValues, oldValues)) fetchRota()
    },
    {
      immediate: true,
    },
  )

  // for updating rota outside of hook
  const updateRota = () => {
    if (loadingRota.value) return

    return
  }

  return {
    loadingRota,
    rota,
    fetchRota,
    updateRota,
  }
}