<template>
  <BaseCard extend-wrapper-classes="max-h-90vh h-full">
    <BaseCardHeader :loading="loading">
      <span class="flex items-center">
        {{ dayToDisplay }}

        <span class="mx-2">
          <BaseIconButton
            rounded
            size="md"
            color="text-white hover:text-gray-300"
            @click="setKeyVisibility(!keyIsVisible)"
          >
            <InfoIcon />
          </BaseIconButton>
        </span>

        <DateViewKey 
          :is-visible="keyIsVisible"
          @leaving="setKeyVisibility(false)"
        />
      </span>

      <template #headingRight>
        <p class="text-white">
          {{ dateToDisplay }}
        </p>
      </template>
    </BaseCardHeader>
    <div
      class="overflow-y-auto h-full"
      :style="{
        maxHeight: 'calc(90vh - 56px)',
      }"
    >
      <DateViewSlot
        v-for="item in slotData"
        :key="item.title"
        :loading="loading"
        :title="item.title"
        :primary-names="item.data?.primaryNames ?? ''"
        :consultant-names="item.data?.consultantNames ?? ''"
        :total-staff="item.data?.totalStaff ?? 0"
      >
        <template
          v-if="item.data?.entries"
          #entries
        >
          <EntryPill
            v-for="entry in item.data.entries"
            :key="entry.entry_id"
            :entry="entry"
            tooltip
            @clicked="pillClickedHandler(entry)"
          />
        </template>

        <span class="block w-49pc relative">
          <AddShiftButton @clicked="openShiftsMenu(item.slotId)" />

          <ShiftSubMenu
            v-if="showShiftsMenu && showingShiftsForSlot === item.slotId"
            :options="getSubMenuOptions(item.slotId)"
            :slot-id="item.slotId"
            @clicked="onShiftOptionClicked"
            @cancel="closeShiftsMenu"
          />
        </span>

        <template #entries-skeleton>
          <div class="mb-1 h-6 w-49pc bg-blue-lightest pulse rounded-full" />
        </template>
      </DateViewSlot>

      <DateViewSlot
        extend-wrapper-classes="mt-6"
        :loading="loading"
        :title="absenceData.title"
        :total-staff="absenceData?.totalStaff ?? 0"
        is-absences
      >
        <template
          v-if="absenceData?.data"
          #entries
        >
          <EntryPill
            v-for="entry in absenceData.data"
            :key="entry.entry_id"
            :entry="entry"
            tooltip
            @clicked="pillClickedHandler(entry)"
          />
        </template>

        <template #entries-skeleton>
          <div class="mb-1 h-6 w-49pc bg-blue-lightest pulse rounded-full" />
        </template>
      </DateViewSlot>
    </div>
  </BaseCard>
</template>

<script lang="ts">
import { computed, defineComponent, PropType, ref } from 'vue'
import moment from 'moment'
import ROTA_ID from '@/constants/rota'
import { absencePillType } from '@/hooks/useSingleUserRotaTable'
import pick from 'lodash/pick'

// Types
import { MasterRotaSingleDate } from '@/types/masterRota'
import { DateBasics, Entry, EntryStatus, Shift, ShiftEntry } from '@/types/roster'

// Components
import InfoIcon from '@/components/icons/toast/InfoIcon.vue'
import DateViewKey from '@/components/rota/master/DateViewKey.vue'
import DateViewSlot from '@/components/rota/master/DateViewSlot.vue'
import EntryPill from '@/components/rota/master/EntryPill.vue'
import AddShiftButton from '@/components/rota/master/AddShiftButton.vue'
import ShiftSubMenu from '@/components/rota/master/ShiftSubMenu.vue'

enum CONS_KEY {
  EARLY_PRIMARY = 'Ep',
  EARLY = 'E',
  MID = 'M',
  LATE_PRIMARY = 'Lp',
  LATE = 'L',
  NIGHT_PRIMARY = 'Np',
}

const CONS_SHIFT_MAP = {
  [CONS_KEY.EARLY_PRIMARY]: [2, 9],
  [CONS_KEY.EARLY]: [1, 8],
  [CONS_KEY.MID]: [3, 4, 10],
  [CONS_KEY.LATE_PRIMARY]: [7, 12],
  [CONS_KEY.LATE]: [5, 6, 11],
  [CONS_KEY.NIGHT_PRIMARY]: [21, 22],
} as {
  [key: string]: number[];
}

const SLOT_CONFIG = [
  {
    title: 'Early',
    id: 1,
    primaryKey: CONS_KEY.EARLY_PRIMARY,
    consultantsKey: CONS_KEY.EARLY,
  },
  {
    title: 'Mid',
    id: 2,
    consultantsKey: CONS_KEY.MID,
  },
  {
    title: 'Late',
    id: 3,
    primaryKey: CONS_KEY.LATE_PRIMARY,
    consultantsKey: CONS_KEY.LATE,
  },
  {
    title: 'Night 1',
    id: 4,
  },
  {
    title: 'Night 2',
    id: 5,
    primaryKey: CONS_KEY.NIGHT_PRIMARY,
  },
]

export default defineComponent({
  components: {
    DateViewSlot,
    DateViewKey,
    EntryPill,
    AddShiftButton,
    ShiftSubMenu,
    InfoIcon,
  },

  props: {
    rota: {
      type: Object as PropType<MasterRotaSingleDate>,
      default: null,
    },
    loading: {
      type: Boolean,
      required: true,
    },
    readonly: {
      type: Boolean,
      required: true,
    },
    dayShiftIds: {
      type: Array as PropType<number[]>,
      required: true,
    },
    shiftMap: {
      type: Object as PropType<{
        [key: string]: Shift;
      }>,
      required: true,
    },
  },

  emits: ['manage-entry', 'create-shift'],

  setup (props, ctx) {
    /**
     * Rendering entries
     */
    const hasData = computed(() => !!props.rota)
    
    const dateToDisplay = computed(() => {
      if (!hasData.value) return '...'
      return moment(props.rota.date).format('DD/MM/YY')
    })

    const dayToDisplay = computed(() => {
      if (!hasData.value) return '...'
      return props.rota.day
    })

    const mapEntriesToSlotData = (
      entries: ShiftEntry[], 
      primaryKey = '', 
      consultantsKey = '') => {
        const sortedEntries = 
          entries.length > 1 
            ? entries.sort((a, b) => (a.user?.sub_grade_id ?? 99) - (b.user?.sub_grade_id ?? 99))
            : entries

        const liveEntries = entries.filter(ent => ent.user_id && [2,3].includes(ent.status_id))

        const totalStaff = new Set(liveEntries.filter(ent => ent.user?.sub_grade_id !== 1).map(ent => ent.user_id)).size

        let primaries: ShiftEntry[] = []
        if (primaryKey) {
          primaries = liveEntries.filter(ent => CONS_SHIFT_MAP[primaryKey].includes(ent.shift.id))
        }
        const primaryNames = primaries.length ? primaries.map(p => p.user?.first_name).join(' | ') : ''

        let consultants: ShiftEntry[] = []
        if (consultantsKey) {
          consultants = liveEntries.filter(ent => CONS_SHIFT_MAP[consultantsKey].includes(ent.shift.id))
        }
        const consultantNames = consultants.length ? consultants.map(c => c.user?.first_name).join(' | ') : ''

        return {
          entries: sortedEntries,
          totalStaff,
          primaryNames,
          consultantNames,
        }
    }

    const slotData = computed(() => {
      if (props.loading || !props.rota) return SLOT_CONFIG.map(config => ({ title: config.title, data: null }))

      return SLOT_CONFIG.map(config => {
        const slotEntries = props.rota.shifts[`slot-${config.id}`]

        return {
          slotId: config.id,
          title: config.title,
          data: slotEntries ? mapEntriesToSlotData(slotEntries, config.primaryKey ?? '', config.consultantsKey ?? '') : null,
        }
      })
    })

    const absenceData = computed(() => {
      if (props.loading || !props.rota) return { title: 'Absences', data: null }

      const liveAbsences = props.rota.absences.filter(ent => ent.user_id && [2,3].includes(ent.status_id))

      return {
        title: 'Absences',
        data: props.rota.absences,
        totalStaff: new Set(liveAbsences.map(ent => ent.user_id)).size,
      }
    })

    /**
     * Adding a new shift
     */
    const showShiftsMenu = ref(false)
    const showingShiftsForSlot = ref<number | null>(null)

    const openShiftsMenu = (slotId: number) => {
      if (props.readonly) return 

      showShiftsMenu.value = true
      showingShiftsForSlot.value = slotId
    }

    const closeShiftsMenu = () => {
      showShiftsMenu.value = false
      showingShiftsForSlot.value = null
    }

    const onShiftOptionClicked = (shiftId: number) => {
      closeShiftsMenu()
      
      if (props.readonly) return

      const date = pick(props.rota, ['id', 'date', 'day_id', 'day'])
      ctx.emit('create-shift', shiftId, date)
    }

    const getSubMenuOptions = (slotId: number) => {
      const options: Shift[] = []

      props.dayShiftIds.forEach(shiftId => {
        if (props.shiftMap[shiftId].slot_id === slotId) options.push(props.shiftMap[shiftId])
      })

      return options
    }

    /**
     * Managing existing shift
     */
    const pillClickedHandler = (entry: Entry) => {
      if (props.readonly) return

      const date = pick(props.rota, ['id', 'date', 'day_id', 'day']) as DateBasics

      // managing pending locum shift.
      if (!entry.user) ctx.emit('manage-entry', date, null, 'updating-locum', entry)

      // collate existingEntries
      let existingEntries: Entry[] = []
      for (const slot in props.rota.shifts) {
        const userEntries = props.rota.shifts[slot].filter(e => e.user_id === entry.user_id)
        existingEntries = [
          ...existingEntries,
          ...userEntries,
        ]
      }
      const userAbsences = props.rota.absences.filter(a => a.user_id === entry.user_id)
      existingEntries = [
        ...existingEntries,
        ...userAbsences,
      ]

       // single entries - action based on entry.EntryStatus
      if (existingEntries.length === 1) {
        const selected = existingEntries[0]

        switch (selected.status) {
          case EntryStatus.DRAFT:
            ctx.emit('manage-entry', date, entry.user, 'approving', selected)
            return
          case EntryStatus.DELETING:
            ctx.emit('manage-entry', date, entry.user, 'deleting', selected)
            return
          case EntryStatus.APPROVED:
            ctx.emit('manage-entry', date, entry.user, 'replacing-entry', selected)
            return
        }
      }
        
      // multi entries - action based on entry.EntryStatus
      if (existingEntries.length > 1) {
        const selected = 
          existingEntries[0].entry_id === entry.entry_id 
            ? existingEntries[0] 
            : existingEntries[1]
        const other = 
          existingEntries[0].entry_id === entry.entry_id 
            ? existingEntries[1] 
            : existingEntries[0]

        switch (selected.status) {
          case EntryStatus.DRAFT:
            ctx.emit('manage-entry', date, entry.user, 'approving', selected, other)
            return
          case EntryStatus.DELETING:
            ctx.emit('manage-entry', date, entry.user, 'deleting', selected, other)
            return
        }
      }

    }

    /**
     * Key
     */
    const keyIsVisible = ref(false)

    const setKeyVisibility = (val: boolean) => {
      keyIsVisible.value = val
    }

    return {
      dayToDisplay,
      dateToDisplay,
      slotData,
      absenceData,
      absencePillType,
      showShiftsMenu,
      showingShiftsForSlot,
      openShiftsMenu,
      closeShiftsMenu,
      onShiftOptionClicked,
      getSubMenuOptions,
      ROTA_ID,
      pillClickedHandler,
      keyIsVisible,
      setKeyVisibility,
    }
  },
})
</script>
