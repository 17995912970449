import { ref, Ref, watch, computed } from 'vue'
import RotasAPI from '@/apis/rota-architect/rotas'
import useToasts from '@/hooks/useToasts'
import parseErrorMap from '@/utils/parseErrorMap'

// Types.
import { UserSuccessResponse } from '@/types/users'

// Constants.
import ROTA_ID from '@/constants/rota'
import { subGradeMap } from '@/constants/entries'


export default function useSuggestions(
  dateId: Ref<number>,
  shiftId: Ref<number>,
) {
  const dispatch = useToasts()

  const loading = ref(false)
  const suggestions = ref<UserSuccessResponse[]>([])

  const fetchSuggestions = () => {
    if (loading.value) return

    loading.value = true

    RotasAPI.suggestions(ROTA_ID.toString(), dateId.value.toString(), shiftId.value.toString())
      .then((res) => {
        suggestions.value = res.data
      })
      .catch((err) => {
        dispatch.errorToast(parseErrorMap(err.response.data))
      })
      .finally(() => {
        loading.value = false
      })
  }

  watch(
    [dateId, shiftId],
    () => {
      fetchSuggestions()
    },
    {
      immediate: true,
    },
  )

  const suggestionOptions = computed(() => {
    if (loading.value || !suggestions.value.length) return []

    return suggestions.value.map(user => ({
      label: user.name,
      grade: subGradeMap[user.sub_grade_id ?? 0],
      id: user.id.toString(),
    }))
  })

  return {
    loading,
    suggestions,
    suggestionOptions,
  }
}