
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    extendWrapperClasses: {
      type: String,
      default: '',
    },
    loading: {
      type: Boolean,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    primaryNames: {
      type: String,
      default: '',
    },
    consultantNames: {
      type: String,
      default: '',
    },
    totalStaff: {
      type: Number,
      default: 0,
    },
    isAbsences: {
      type: Boolean,
      default: false,
    },
  },

  setup () {
    

    return {}
  },
})
