
import { defineComponent } from 'vue'
import BareAdditionIcon from '@/components/icons/BareAdditionIcon.vue'

export default defineComponent({
  components: {
    BareAdditionIcon,
  },

  emits: ['clicked'],

  setup () {
    

    return {}
  },
})
