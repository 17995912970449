import { ref, Ref, watch } from 'vue'

// Types.
import { ShiftEntry } from '@/types/roster'
import { StaffingGroup } from '@/types/staffingGroup'

export interface StaffingGroupCountMap {
  [key: number]: {
    name: string;
    min_count: number;
    max_count: number | null;
    pref_count: number;
    current_count: number;
    has_additional: boolean;
    additional: {
      [key: string]: {
        label: string;
        current: number;
        min?: number;
        max?: number;
        preferred?: number;
      };
    };
  };
}

export default function useStaffingCounter(
  entries: Ref<ShiftEntry[]>,
  dailyStaffingGroupIds: number[],
  staffingGroupMap: {
    [key: string]: StaffingGroup;
  },
) {

  const map = ref<StaffingGroupCountMap>({})

  const generateMap = () => {
    // initialize map for possible staffing_group_ids
    dailyStaffingGroupIds.forEach(id => {
      map.value[id] = {
        name: staffingGroupMap[id].name,
        min_count: staffingGroupMap[id].min_total,
        max_count: staffingGroupMap[id].max_total,
        pref_count: staffingGroupMap[id].pref_total,
        current_count: 0,
        has_additional: !!staffingGroupMap[id].additional_requirements.length,
        additional: {},
      }

      if (map.value[id].has_additional) {
        // loop through additional_requirements array, an collate into map
        staffingGroupMap[id].additional_requirements.forEach(req => {
          map.value[id].additional[`${req.resource}_${req.resource_id}`] = {
            // spread in any previous additional_reqs for the same resource
            ...map.value[id].additional[`${req.resource}_${req.resource_id}`], 
            label: req.resource_label,
            current: 0,
            [req.rule]: req.value,
          }
        })
      }
    })

    // loop through shifts and update count.
    entries.value.forEach(entry => {
      map.value[entry.shift.staffing_group_id].current_count++

      // check if has_additional, and then specifically pertaining to the users grade or subgrade
      if (map.value[entry.shift.staffing_group_id].has_additional) {
        if (`grade_${entry.user?.grade_id}` in map.value[entry.shift.staffing_group_id].additional) {
          map.value[entry.shift.staffing_group_id].additional[`grade_${entry.user?.grade_id}`].current++
        }

        if (`sub_grade_${entry.user?.sub_grade_id}` in map.value[entry.shift.staffing_group_id].additional) {
          map.value[entry.shift.staffing_group_id].additional[`sub_grade_${entry.user?.sub_grade_id}`].current++
        }
      }
    })
  }

  watch(
    [entries, dailyStaffingGroupIds],
    () => {
      generateMap()
    },
    {
      immediate: true,
    },
  )

  return {
    map,
  }
}