
import { defineComponent, ref } from 'vue'
import debounce from 'lodash/debounce'
import useToasts from '@/hooks/useToasts'
import UsersApi from '@/apis/rota-architect/users'
import parseErrorMap from '@/utils/parseErrorMap'

// Components
import SearchIcon from '@/components/icons/SearchIcon.vue'

export default defineComponent({
  inheritAttrs: false,

  components: {
    SearchIcon,
  },

  props: {
    readonly: {
      type: Boolean,
      default: false,
    },
    rotaId: {
      type: Number,
      required: true,
    },
  },

  emits: ['users'],

  setup (props, ctx) {
     const dispatch = useToasts()

    const searching = ref(false)
    const search = ref('')

    const fetchUsers = () => {
      searching.value = true

      const queryString = `?rota=${props.rotaId}&search=${search.value}`
      UsersApi.all(queryString)
        .then(res => {
          ctx.emit('users', res.data.data)
        })
        .catch(err => {
          dispatch.errorToast(parseErrorMap(err.response.data))
        })
    }

    const debouncedSearch = debounce(() => {
      if (!search.value) {
        ctx.emit('users', [])
        return
      }

      if (search.value.length < 3) return

      fetchUsers()
    }, 500)

    return {
      search,
      debouncedSearch,
    }
  },
})
