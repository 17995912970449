import { ref } from 'vue';

// Types
import { SelectOption } from '@/types/form'
import { UtilitySuccessReponse, APIResource } from '@/types/utility'

// Hooks
import useDispatch from '@/hooks/useToasts'

// API's
import GradesApi from '@/apis/rota-architect/grades'
import SubGradesApi from '@/apis/rota-architect/subGrades'
import SitesApi from '@/apis/rota-architect/locations'
import RotasApi from '@/apis/rota-architect/rotas'
import ContractsApi from '@/apis/rota-architect/contracts'

const getApiFn = (type: APIResource) => {
  const MAP = {
    'Grade': GradesApi.all,
    'SubGrade': SubGradesApi.all,
    'Site': SitesApi.all,
    'Rota': RotasApi.all,
    'Contract': ContractsApi.all,
  }
  return MAP[type]
}

export default function useSelectOptions(model: APIResource) {
  const loading = ref(true)
  const options = ref<SelectOption[] | []>([])

  const dispatch = useDispatch()

  const mapDataToSelectOptions = (data: UtilitySuccessReponse[]) => data.map(
    (i) => ({
        value: i.id,
        label: i.label,
      }),
  )

  getApiFn(model)()
    .then((res) => {
      options.value = mapDataToSelectOptions(res.data)
    })
    .catch(() => {
      dispatch.errorToast(`Failed to load ${model} filter options.`)
    })
    .finally(() => {
      loading.value = false
    })

  return {
    loading,
    options,
  }
}