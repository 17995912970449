<template>
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    class="h-full w-full"
    viewBox="0 0 260 260"
    enable-background="new 0 0 260 260"
    xml:space="preserve"
  >
    <g
      id="Background"
      display="none"
    >
      <rect
        display="inline"
        fill="#333333"
        width="260"
        height="260"
      />
    </g>
    <circle
      display="none"
      fill="none"
      stroke="currentColor"
      stroke-width="10"
      stroke-miterlimit="10"
      cx="130"
      cy="130"
      r="122.5"
    />
    <line
      fill="none"
      stroke="currentColor"
      stroke-width="25"
      stroke-miterlimit="10"
      x1="130.25"
      y1="5.5"
      x2="130.25"
      y2="257"
    />
    <line
      fill="none"
      stroke="currentColor"
      stroke-width="25"
      stroke-miterlimit="10"
      x1="256"
      y1="129.946"
      x2="4.5"
      y2="129.946"
    />
  </svg>
</template>