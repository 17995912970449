<template>
  <div
    class="absolute top-6 left-0 w-full z-20 shadow-md rounded-lg p-1 bg-gray-500"
    @mouseleave="$emit('cancel')"
  >
    <button
      class="flex items-center active:outline-none focus:outline-none transition-colors ease-in duration-300 mb-1 hover:bg-opacity-75 text-xs w-full bg-white border-2 border-white rounded-full"
      v-for="opt in options"
      :key="opt.id"
      @click="$emit('clicked', opt.id)"
    >
      <span class="flex items-center justify-center text-blue-medium w-12 flex-shrink-0">
        {{ gradeMap[opt.grade_id] }} 
      </span>
      <div
        class="flex items-center w-full px-1 truncate rounded-r-full"
        :class="themeClasses"
      >
        {{ shiftTextContent(opt, 'name', true) }}
      </div>
    </button>
  </div>
</template>

<script lang="ts">
import { Shift } from '@/types/roster'
import { defineComponent, PropType } from 'vue'
import { pillThemeMap, gradeMap } from '@/constants/entries'
import { shiftTextContent, shiftPillType } from '@/hooks/useSingleUserRotaTable'

export default defineComponent({
  props: {
    options: {
      type: Array as PropType<Shift[]>,
      required: true,
    },
    slotId: {
      type: Number,
      required: true,
    },
  },

  emits: ['clicked', 'cancel'],

  setup (props) {
    
    return {
      gradeMap,
      shiftTextContent,
      themeClasses: pillThemeMap[shiftPillType(props.slotId)],
    }
  },
})
</script>
