
import { computed, defineComponent, PropType } from 'vue'
import { entryPillType } from '@/hooks/useSingleUserRotaTable'
import { pillThemeMap } from '@/constants/entries'
import shiftNameToInitials from '@/utils/shiftNameToInitials'
import nameToInitials from '@/utils/nameToInitials'

// Types
import { Entry, EntryStatus } from '@/types/roster'

// Components
import DeletingStatusIcon from '@/components/icons/rota/DeletingStatusIcon.vue'
import DraftStatusIcon from '@/components/icons/rota/DraftStatusIcon.vue'

export default defineComponent({
  components: {
    DeletingStatusIcon,
    DraftStatusIcon,
  },

  props: {
    entry: {
      type: Object as PropType<Entry>,
      required: true,
    },
    tooltip: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['clicked'],

  setup (props) {
    
    const isDeleting = computed(() => props.entry.status === EntryStatus.DELETING)

    const isDraft = computed(() => props.entry.status === EntryStatus.DRAFT)

    const isLocum = computed(() => props.entry.is_locum)
    
    const isAbsence = computed(() => props.entry.type === 'absences')

    const isWithAbsence = computed(() => !!props.entry.with_absence_id)

    const abbreviatedName = computed(() => {
      if (props.entry.type === 'shifts') {
        return shiftNameToInitials(props.entry.shift.name)
      }

      if (props.entry.type === 'absences') {
        return nameToInitials(props.entry.absence.label)
      }

      return ''
    })

    const themeClasses = computed(() => {
      const type = entryPillType(props.entry);
      if (type ) return pillThemeMap[type]
      return ''
    })

    const borderClasses = computed(() => {
      if (isWithAbsence.value) return 'border-entry-absence'

      if (isLocum.value && !isWithAbsence.value) return 'border-blue-light'

      return 'border-white'
    })

    return {
      sizeClasses: 'h-7 w-7 border-2 text-xs rounded-full',
      themeClasses,
      borderClasses,
      isDeleting,
      isDraft,
      isLocum,
      isAbsence,
      abbreviatedName,
      isWithAbsence,
    }
  },
})
