<template>
  <BaseViewWrapper>
    <MasterRotaDashboard>
      <template #header>
        <BaseHeader>
          <div>
            <BaseLabel>
              Showing Master Rota from:
            </BaseLabel>
            <span class="flex items-center relative">
              <DateInput 
                :starting-year="startingYear"
                :number-of-years="numberOfYears"
                :disabled="disableFilters"
                v-model="dateInputValue"
              />

              <span class="absolute -top-7 xxl:top-1/2 transform xxl:-translate-y-1/2 -right-16 xxl:-right-28 z-10">
                <transition name="fade">
                  <BaseButton
                    v-if="showBackToToday"
                    theme="success"
                    size="xs"
                    :disabled="disableFilters"
                    @click="goToToday"
                  >
                    Back to today
                  </BaseButton>
                </transition>
              </span>
            </span>
          </div>

          <div class="w-56 xxl:w-64">
            <SelectInput
              label="View by Grade"
              :loading-options="loadingGradeOptions"
              :options="gradeOptions"
              :disabled="disableFilters"
              size="sm"
              v-model="gradeInputValue"
            /> 
          </div>

          <div :class="[gradeInputValue ? 'opacity-50' : 'opacity-100']">
            <BaseLabel>
              Staffing levels calculated from
            </BaseLabel>
            <span class="flex items-center mr-auto bg-blue-medium px-4 py-1 rounded-md text-sm">
              <button 
                class="mr-2 transition-colors ease-in-out duration-200 active:outline-none focus:outline-none has-tooltip"
                :class="[
                  usingLiveData ? 'text-white' : 'text-gray-800',
                  gradeInputValue ? 'cursor-not-allowed' : '',
                ]"
                :disabled="!!gradeInputValue || disableFilters"
                @click="setUsingLiveData"
              >
                Live data
                <BaseToolTip>
                  Shifts 'deleting' or 'approved'
                </BaseToolTip>
              </button>
              <BaseToggle
                :readonly="!!gradeInputValue || disableFilters"
                :value="usingLiveData"
                :alternate="false"
                @toggle="toggleDataType"
              />
              
              <button 
                class="ml-2 transition-colors ease-in-out duration-200 active:outline-none focus:outline-none has-tooltip"
                :class="[
                  usingLiveData ? 'text-gray-800' : 'text-white',
                  gradeInputValue ? 'cursor-not-allowed' : '',
                ]"
                :disabled="!!gradeInputValue || disableFilters"
                @click="setUsingGhostData"
              >
                Ghost data
                <BaseToolTip>
                  Shifts 'draft', 'approved', or 'pending'
                </BaseToolTip>
              </button>
            </span>
          </div>
        </BaseHeader>
      </template>

      <template #main>
        <StaffingTable
          v-if="!gradeIdFilter" 
          :rota="masterRota"
          :loading="loadingMasterRota"
          :readonly="readonlyMasterRota"
          :selected-row-id="selectedDateRota?.id ?? 0"
          :day-id-to-shifts-map="dayIdToShiftsMap ?? {}"
          :shift-map="shiftMap ?? {}"
          :day-id-to-staffing-group-map="dayIdToStaffingGroupsMap ?? {}"
          :staffing-group-map="staffingGroupMap ?? {}"
          :data-type="rotaDataType"
          @selected="onChangeSelectedDate"
          @create-shift="onCreateShift"
        />
        <GradeUsersTable 
          v-else
          :grade-id="gradeIdFilter"
          :grade-rota="masterRota"
          :loading="loadingMasterRota"
          :readonly="readonlyMasterRota"
          :selected-row-id="selectedDateRota?.id ?? 0"
          @create-entry="onCreateUserEntry"
          @manage-entry="onManageEntry"
          @selected="onChangeSelectedDate"
        />
      </template>

      <template #sidebar>
        <DateView 
          :rota="selectedDateRota"
          :loading="loadingDateView"
          :readonly="readonlyDateView"
          :day-shift-ids="
            dayIdToShiftsMap && selectedDateRota?.day_id 
              ? dayIdToShiftsMap[selectedDateRota.day_id] 
              : []"
          :shift-map="shiftMap ?? {}"
          @manage-entry="onManageEntry"
          @create-shift="onCreateShift"
        />
      </template>
    </MasterRotaDashboard>

    <BaseModal
      extend-wrapper-classes="bg-white shadow-xl"
      size="md"
      :show="modalIsVisible"
      @hide="hideModal"
    >
      <EntryCreateForm
        v-if="action === 'creating-entry' || action === 'replacing-entry'"
        :date="entryDate"
        :user-name="userForEntry.name"
        :user-id="userForEntry.id"
        :current-entry="existingEntry"
        @success="managementSuccess"
        @cancel="managementCancelled"
      />
      
      <ShiftCreateForm
        v-else-if="action === 'creating-shift'"
        :date="entryDate"
        :shift="shiftMap && creatingShiftId ? shiftMap[creatingShiftId] : null"
        :annual-leave-entries="annualLeaveEntries"
        @success="managementSuccess"
        @cancel="managementCancelled"
      />

      <ShiftUpdateForm
        v-if="action === 'updating-locum'"
        :date="entryDate"
        :current-entry="existingEntry"
        :annual-leave-entries="annualLeaveEntries"
        @success="managementSuccess"
        @cancel="managementCancelled"
      />

      <EntryApprovalForm
        v-else-if="action === 'approving'"
        :date="entryDate"
        :user-name="approvingEntry?.user.name ?? ''"
        :approving-entry="approvingEntry"
        :deleting-entry="deletingEntry"
        @success="managementSuccess"
        @cancel="managementCancelled"
      />

      <EntryDeletingForm
        v-else-if="action === 'deleting'"
        :date="entryDate"
        :user-name="deletingEntry?.user.name ?? ''"
        :deleting-entry="deletingEntry"
        :persist-entry="existingEntry"
        @success="managementSuccess"
        @cancel="managementCancelled"
      />
    </BaseModal>

    <BaseWorking :working="readonlyDateView || readonlyMasterRota" />
  </BaseViewWrapper>
</template>

<script lang="ts">
import { defineComponent, ref, watch, computed } from 'vue'
import moment from 'moment'
import ROTA_ID from '@/constants/rota'

// Types
import { DateBasics, Entry } from '@/types/roster'
import { UserBasics } from '@/types/users'

// Hooks
import { useRouter, useRoute } from 'vue-router'
import useToasts from '@/hooks/useToasts'
import usePeriodStartDate from '@/hooks/usePeriodStartDate'
import useMasterRota from '@/hooks/useMasterRota'
import useSelectOptions from '@/hooks/useSelectOptions'
import useMasterRotaByDate from '@/hooks/useMasterRotaByDate'
import useWeeklyRotaRequirements from '@/hooks/useWeeklyRotaRequirements'
import useWeeklyAvailableShifts from '@/hooks/useWeeklyAvailableShifts'

// Components
import MasterRotaDashboard from '@/components/rota/layouts/MasterRotaDashboard.vue'
import DateView from '@/components/rota/master/DateView.vue'
import StaffingTable from '@/components/rota/master/StaffingTable.vue'
import GradeUsersTable from '@/components/rota/master/GradeUsersTable.vue'
import ShiftCreateForm from '@/components/rota/form/ShiftCreateForm.vue'
import ShiftUpdateForm from '@/components/rota/form/ShiftUpdateForm.vue'
import EntryCreateForm from '@/components/rota/form/EntryCreateForm.vue'
import EntryApprovalForm from '@/components/rota/form/EntryApprovalForm.vue'
import EntryDeletingForm from '@/components/rota/form/EntryDeletingForm.vue'

export enum RotaDataType {
  LIVE = 'live',
  GHOST = 'ghost',
}

export default defineComponent({
  components: {
    MasterRotaDashboard,
    DateView,
    StaffingTable,
    GradeUsersTable,
    ShiftCreateForm,
    ShiftUpdateForm,
    EntryCreateForm,
    EntryApprovalForm,
    EntryDeletingForm,
  },

  setup () {
    const router = useRouter()
    const route = useRoute()
    const { warningToast } = useToasts()
    const refreshing = ref(false)

    /**
     * Fetch MasterRota
     */
    const startDateString = computed(() => {
      if (
        route.query?.start_date && 
        moment(route.query.start_date.toString()).isValid()
      ) {
        return route.query.start_date.toString()
      }
      return moment().format('YYYY-MM-DD')
    })

    const endDateString = computed(() => {
      return moment(startDateString.value).add(2, 'weeks').format('YYYY-MM-DD')
    })
    
    const rotaId = ref(ROTA_ID.toString())

    const gradeIdFilter = computed(() => {
      if (route.query?.grade) return +route.query.grade
      return null
    })

    const {
      rota: masterRota, 
      loadingRota,
      fetchRota: fetchMasterRota,
    } = useMasterRota(rotaId, startDateString, endDateString, gradeIdFilter)

    const {
      loading: loadingWeeklyRequirements,
      dayIdToStaffingGroupsMap, // { day_id: [{staffing_group_id}, ...] }
      staffingGroupMap, // { id: {staffing_group} }
    } = useWeeklyRotaRequirements(rotaId)

    const {
      loading: loadingWeeklyShifts,
      dayIdToShiftsMap, // { day_id: [{shift_id}, {shift_id}] }
      shiftMap, // { id: {shift} }
    } = useWeeklyAvailableShifts(rotaId)

    /**
     * Filtering Master Rota
     */
    // Start date
    const {
      startDate: inputMin,
    } = usePeriodStartDate()

    const dateInputValue = ref(startDateString.value)
  
    const startingYear = computed(() => moment(inputMin.value).year() ?? moment().year())
    const numberOfYears = computed(() => moment(inputMin.value).diff(moment(), 'years') + 1)

    const showBackToToday = computed(() => startDateString.value !== moment().format('YYYY-MM-DD'))

    const goToToday = () => {
      dateInputValue.value = moment().format('YYYY-MM-DD')
    }

    // Grade users
    const gradeInputValue = ref(gradeIdFilter.value?.toString() || '')

    const { 
      options: gradeOptions, 
      loading: loadingGradeOptions,
    } = useSelectOptions('Grade')

    // Rota data type
    const rotaDataType = ref(RotaDataType.LIVE)

    const usingLiveData = computed(() => rotaDataType.value === RotaDataType.LIVE)

    const toggleDataType = () => {
      rotaDataType.value === RotaDataType.LIVE 
        ? rotaDataType.value = RotaDataType.GHOST
        : rotaDataType.value = RotaDataType.LIVE
    }

    const setUsingLiveData = () => {
      rotaDataType.value = RotaDataType.LIVE
    }

    const setUsingGhostData = () => {
      rotaDataType.value = RotaDataType.GHOST
    }

   
    /**
     * Selected Date View
     */
    const selectedDateFromQuery = computed(() => route.query?.selected_date?.toString() ?? '') 

    const selectedDateId =ref(selectedDateFromQuery.value)

    const { 
      rota: selectedDateRota, 
      loadingRota: loadingSelectedDateRota,
      fetchRota: fetchSelectedDateRota,
    } = useMasterRotaByDate(rotaId, selectedDateFromQuery)

    const onChangeSelectedDate = (dateId: string) => {
      selectedDateId.value = dateId
    }

    // whenever startDate is changed, reset selectedDateId.
    watch(
      startDateString,
      () => {
        selectedDateId.value = ''
      },
    )

    // whenever masterRota stops loading, set selectedDate to the first date if not already set.
    watch(
      loadingRota,
      (newValue: boolean, oldValue: boolean) => {
        if (!selectedDateId.value && !newValue && oldValue && masterRota.value.length) {
          selectedDateId.value = masterRota.value[0].id.toString()
        }
      },
    )

     // Filter input watchers
    interface FilterQueryObject {
      [key: string]: string;
    }

    watch(dateInputValue, () => {
      const newDate = moment(dateInputValue.value)

      if (newDate.isBefore(inputMin.value)) {
        warningToast(`'From' date cannot be before ${moment(inputMin.value).format('DD MMM YYYY')}.`, 'Date Range')
        return
      }

      const max = moment().add(11, 'months')
      if (newDate.isAfter(max)) {
        warningToast(`'From' date cannot be after ${max.format('DD MMM YYYY')}.`, 'Date Range')
        return
      }

      const query: FilterQueryObject = {
        start_date: dateInputValue.value,
        selected_date: selectedDateId.value,
      }

      if (gradeInputValue.value) {
        query.grade = gradeInputValue.value.toString()
      }
      
      router.replace({
        query,
      })
    })

    watch(gradeInputValue, () => {
      if (gradeInputValue.value) {
        router.replace({
          query: {
            start_date: startDateString.value,
            grade: gradeInputValue.value,
            selected_date: selectedDateId.value,
          },
        })
      }

      else {
        router.replace({
          query: {
            start_date: startDateString.value,
            selected_date: selectedDateId.value,
          },
        })
      }
    })

    watch(selectedDateId, () => {
      if (route.name === 'master-rota') {
        router.replace({
          query: {
            ...route.query,
            selected_date: selectedDateId.value,
          },
      })
      }
    })

    /**
     * Entry Management
     */
    const modalIsVisible = ref(false)
    const action = ref('')
    const creatingShiftId = ref<number | null>(null)
    const userForEntry = ref<UserBasics | null>(null)
    const entryDate = ref<DateBasics | null>(null)
    const existingEntry = ref<Entry | null>(null)
    const approvingEntry = ref<Entry | null>(null)
    const deletingEntry = ref<Entry | null>(null)

    const hideModal = () => {
      modalIsVisible.value = false
    }

    // For when creating a user-less shift
    const annualLeaveEntries = computed(() => {
      if (loadingSelectedDateRota.value || !selectedDateRota.value) return []

      return selectedDateRota.value.absences.filter(abs => abs.absence.id === 2 && [2,3].includes(abs.status_id))
    })

    // handles new user-less shift creation - via DateView or StaffingTable click events.
    const onCreateShift = (shiftId: number, date: DateBasics) => {
      modalIsVisible.value = true

      creatingShiftId.value = shiftId
      entryDate.value = date
      action.value = 'creating-shift'
    }

    // handles new draft creation only, not replacements - via GradeUsersTable click event (user known).
    const onCreateUserEntry = (
      date: DateBasics, 
      user: UserBasics, 
      existing: Entry | null = null, // will be a 'deleting' if present.
    ) => {
      modalIsVisible.value = true

      entryDate.value = date
      userForEntry.value = user
      existingEntry.value = existing
      action.value = 'creating-entry'
    }

    // handles remaining operations - via GradeUsersTable and DateView click events.
    const onManageEntry = (
      date: DateBasics, 
      user: UserBasics,
      actionParam: 'approving' | 'deleting' | 'replacing-entry' | 'updating-locum',
      selected: Entry,
      other: Entry | null = null, // has a value if multi-entry
    ) => {
      modalIsVisible.value = true

      entryDate.value = date
      userForEntry.value = user
      action.value = actionParam

      switch (actionParam) {
        case 'approving':
          approvingEntry.value = selected
          deletingEntry.value = other
          return
        case 'deleting':
          deletingEntry.value = selected
          existingEntry.value = other
          return
        case 'replacing-entry':
          existingEntry.value = selected
          return
        case 'updating-locum':
          existingEntry.value = selected
          return
      }
    }

    const resetEntryManagementState = () => {
      action.value = ''
      entryDate.value = null
      existingEntry.value = null
      approvingEntry.value = null
      deletingEntry.value = null
      userForEntry.value = null
      creatingShiftId.value = null
    }

    const managementSuccess = () => {
      resetEntryManagementState()
      hideModal()

      // prevent these two from create a loading state using 'refreshing'
      refreshing.value = true
      Promise.allSettled([fetchSelectedDateRota(), fetchMasterRota()])
        .then(() => {
          console.log('all settled')
          refreshing.value = false
        })
    }

    const managementCancelled = () => {
      resetEntryManagementState()
      hideModal()
    }

    /**
     * Handle UI loading and readonly
     */  
    const loadingDateView = computed(() => {
      return loadingSelectedDateRota.value // loading state and
        && !refreshing.value // not set to readonly
    })

    const readonlyMasterRota = computed(() => {
      return loadingDateView.value || refreshing.value
    })
    
    const loadingMasterRota = computed(() => {
      return (loadingRota.value || loadingWeeklyShifts.value || loadingWeeklyRequirements.value) // loading state and
        && !refreshing.value // not set to readonly
    })

    const readonlyDateView = computed(() => {
      return loadingMasterRota.value || refreshing.value
    })

    const disableFilters = computed(() => {
      return loadingMasterRota.value || readonlyMasterRota.value || loadingDateView.value || readonlyDateView.value
    })

    return {
      // Master Rota
      masterRota,
      loadingMasterRota,
      readonlyMasterRota,
      // Filtering Start Date
      dateInputValue,
      startingYear,
      numberOfYears,
      goToToday,
      showBackToToday,
      // Filtering Grade
      gradeOptions,
      loadingGradeOptions,
      gradeInputValue,
      gradeIdFilter,
      // Filtering Data Type
      rotaDataType,
      usingLiveData,
      setUsingLiveData,
      setUsingGhostData,
      toggleDataType,
      // Date View
      selectedDateRota,
      loadingDateView,
      readonlyDateView,
      onChangeSelectedDate,
      // Staffing
      staffingGroupMap,
      dayIdToStaffingGroupsMap,
      shiftMap,
      dayIdToShiftsMap,
      // Entry Management
      onManageEntry,
      onCreateShift,
      onCreateUserEntry,
      annualLeaveEntries,
      modalIsVisible,
      hideModal,
      action,
      entryDate,
      existingEntry,
      approvingEntry,
      deletingEntry,
      creatingShiftId,
      userForEntry,
      managementSuccess,
      managementCancelled,
      //
      disableFilters,
    }
  },
})
</script>
