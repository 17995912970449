<template>
  <BaseCard extend-wrapper-classes="h-full">
    <DataTable
      extend-wrapper-classes="h-full overflow-y-auto pt-1 px-1 pb-2 xl:pb-4"
      :columns="columns"
      :rows="rows"
      :selected-row-id="selectedRowId"
      :loading="loading"
      clickable
      @row-clicked="rowClickedHandler"
    >
      <template #column-date="{ value, row }">
        <div class="text-center">
          <p class="text-sm -mb-1">
            {{ value }}
          </p>
          <p class="text-xs">
            {{ row.day }} <span v-if="row.isHoliday">(PH)</span>
          </p>
        </div>
      </template>

      <template #column-cons_shifts="{ value, row }">
        <div class="flex justify-center items-center">
          <StaffingTableShift
            v-for="id in value"
            :key="`${row.id}-${id}`"
            :staffing-group-id="shiftMap ? shiftMap[id].staffing_group_id : null"
            :count-map="row.countMap ?? {}"
            :tooltip-location="row.index < 9 ? TooltipLocation.BELOW_RIGHT : TooltipLocation.ABOVE_RIGHT"
            @clicked="shiftClickedHandler(id, row.dateBasics)"
          >
            {{ shiftNameToInitials(shiftMap[id.toString()].name) }}
          </StaffingTableShift>
        </div>
      </template>

      <template #column-reg_shifts="{ value, row }">
        <div class="flex justify-center items-center">
          <StaffingTableShift
            v-for="id in value"
            :key="`${row.id}-${id}`"
            :staffing-group-id="shiftMap ? shiftMap[id].staffing_group_id : null"
            :count-map="row.countMap ?? {}"
            :tooltip-location="row.index < 9 ? TooltipLocation.BELOW_RIGHT : TooltipLocation.ABOVE_RIGHT"
            @clicked="shiftClickedHandler(id, row.dateBasics)"
          >
            {{ shiftNameToInitials(shiftMap[id.toString()].name) }}
          </StaffingTableShift>
        </div>
      </template>

      <template #column-asoc_shifts="{ value, row }">
        <div class="flex justify-center items-center">
          <StaffingTableShift
            v-for="id in value"
            :key="`${row.id}-${id}`"
            :staffing-group-id="shiftMap ? shiftMap[id].staffing_group_id : null"
            :count-map="row.countMap ?? {}"
            :tooltip-location="row.index < 9 ? TooltipLocation.BELOW_LEFT : TooltipLocation.ABOVE_LEFT"
            @clicked="shiftClickedHandler(id, row.dateBasics)"
          >
            {{ shiftNameToInitials(shiftMap[id.toString()].name) }}
          </StaffingTableShift>
        </div>
      </template>

      <template #column-sho_shifts="{ value, row }">
        <div class="flex justify-center items-center">
          <StaffingTableShift
            v-for="id in value"
            :key="`${row.id}-${id}`"
            :staffing-group-id="shiftMap ? shiftMap[id].staffing_group_id : null"
            :count-map="row.countMap ?? {}"
            :tooltip-location="row.index < 9 ? TooltipLocation.BELOW_LEFT : TooltipLocation.ABOVE_LEFT"
            @clicked="shiftClickedHandler(id, row.dateBasics)"
          >
            {{ shiftNameToInitials(shiftMap[id.toString()].name) }}
          </StaffingTableShift>
        </div>
      </template>

      <template #column-nmp_shifts="{ value, row }">
        <div class="flex justify-center items-center">
          <StaffingTableShift
            v-for="id in value"
            :key="`${row.id}-${id}`"
            :staffing-group-id="shiftMap ? shiftMap[id].staffing_group_id : null"
            :count-map="row.countMap ?? {}"
            :tooltip-location="row.index < 9 ? TooltipLocation.BELOW_LEFT : TooltipLocation.ABOVE_LEFT"
            @clicked="shiftClickedHandler(id, row.dateBasics)"
          >
            {{ shiftNameToInitials(shiftMap[id.toString()].name) }}
          </StaffingTableShift>
        </div>
      </template>
    </DataTable>
  </BaseCard>
</template>

<script lang="ts">
import { defineComponent, PropType, computed, ref } from 'vue'
import moment from 'moment'
import shiftNameToInitials from '@/utils/shiftNameToInitials'
import pick from 'lodash/pick'

// Hooks
import useStaffingCounter from '@/hooks/useStaffingCounter'

// Types
import { DateBasics, RosterDate, Shift, ShiftEntry } from '@/types/roster'
import { StaffingGroup } from '@/types/staffingGroup'
import { RotaDataType } from '@/views/MasterRota.vue'
import { TooltipLocation } from '@/components/rota/master/StaffingTableShift.vue'

// Components
import StaffingTableShift from '@/components/rota/master/StaffingTableShift.vue'


export default defineComponent({
  components: {
    StaffingTableShift,
  },

  props: {
    rota: {
      type: Array as PropType<RosterDate<ShiftEntry>[]>,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
    readonly: {
      type: Boolean,
      required: true,
    },
    selectedRowId: {
      type: Number,
      default: 0,
    },
    shiftMap: {
      type: Object as PropType<{
        [key: string]: Shift;
      }>,
      required: true,
    },
    dayIdToShiftsMap: {
      type: Object as PropType<{
        [key: string]: number[];
      }>,
      required: true,
    },
    staffingGroupMap: {
      type: Object as PropType<{
        [key: string]: StaffingGroup;
      }>,
      required: true,
    },
    dayIdToStaffingGroupMap: {
      type: Object as PropType<{
        [key: string]: number[];
      }>,
      required: true,
    },
    dataType: {
      type: String,
      validator: (val: RotaDataType) => [RotaDataType.GHOST, RotaDataType.LIVE].includes(val),
      required: true,
    },
  },

  emits: ['selected', 'create-shift'],

  setup (props, ctx) {
    
    const rows = computed(() => {
      if (props.loading || !props.rota.length) return []

      // dictates what type of entries are used to calculate staffing fulfilment
      let includedStatusesArray: number[]
      switch (props.dataType) {
        case RotaDataType.LIVE:
          includedStatusesArray = [2, 3] // approved, deleting
          break
        case RotaDataType.GHOST:
          includedStatusesArray = [1,2,4] // draft, approved, pending
          break
        default:
          includedStatusesArray = [2, 3]
          break
      }

      return props.rota.map((rotaData, index) => {

        // should this be a ref?
        const entries = ref(rotaData.entries.filter(e => includedStatusesArray.includes(e.status_id)))

        const {
          map: staffingCountMap,
        } = useStaffingCounter(
          entries, 
          props.dayIdToStaffingGroupMap[rotaData.day_id], 
          props.staffingGroupMap,
        )

        return {
          id: rotaData.id,
          day: rotaData.day,
          isHoliday: rotaData.is_holiday,
          date: moment(rotaData.date).format('DD/MM/YY'),
          dateBasics: pick(rotaData, ['id', 'date', 'day_id', 'day']),
          index: index,
          countMap: staffingCountMap.value,
          cons_shifts: props.dayIdToShiftsMap[rotaData.day_id].filter(id => props.shiftMap[id.toString()].grade_id === 1),
          reg_shifts: props.dayIdToShiftsMap[rotaData.day_id].filter(id => props.shiftMap[id.toString()].grade_id === 2),
          asoc_shifts: props.dayIdToShiftsMap[rotaData.day_id].filter(id => props.shiftMap[id.toString()].grade_id === 3),
          sho_shifts: props.dayIdToShiftsMap[rotaData.day_id].filter(id => props.shiftMap[id.toString()].grade_id === 4),
          nmp_shifts: props.dayIdToShiftsMap[rotaData.day_id].filter(id => props.shiftMap[id.toString()].grade_id === 5),
         }
      })
    })

    const rowClickedHandler = (rowId: number) => {
      if (props.readonly) return

      ctx.emit('selected', rowId)
    }

    const shiftClickedHandler = (shiftId: number, date: DateBasics) => {
      if (props.readonly) return

      ctx.emit('create-shift', shiftId, date)
    }

    return {
      columns: [
        { name: 'date', label: 'Date', align: 'center', extendClasses: 'w-32' },
        { name: 'cons_shifts', label: 'Consultant', align: 'center', textless: true },
        { name: 'reg_shifts', label: 'Registrar', align: 'center', textless: true },
        { name: 'asoc_shifts', label: 'Assoc. Specialist', align: 'center', textless: true },
        { name: 'sho_shifts', label: 'SHO', align: 'center', textless: true },
        { name: 'nmp_shifts', label: 'NMP', align: 'center', textless: true },
      ],
      rows,
      rowClickedHandler,
      shiftClickedHandler,
      shiftNameToInitials,
      TooltipLocation,
    }
  },
})
</script>
