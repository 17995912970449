<template>
  <BaseCard extend-wrapper-classes="h-full">
    <DataTable
      extend-wrapper-classes="h-full overflow-y-auto pt-1 px-1 pb-2 xl:pb-4"
      :columns="columns"
      :rows="rows"
      :selected-row-id="selectedRowId"
      :loading="loadingTable"
      clickable
      @row-clicked="rowClickedHandler"
    >
      <template
        v-for="user in gradeUsersList"
        :key="user.id"
        #[`column-heading-user-${user.id}`]
      >
        <div class="text-center w-32 py-2 px-1">
          <p class="truncate max-w-full">
            {{ user.name }}
          </p>
          <p class="text-sm text-gray-300">
            {{ subGradeMap[user.sub_grade_id] }}
          </p>
        </div>
      </template>

      <template #column-date="{ value, row }">
        <div class="text-center">
          <p class="text-sm -mb-1">
            {{ value }}
          </p>
          <p class="text-xs">
            {{ row.day }} <span v-if="row.isHoliday">(PH)</span>
          </p>
        </div>
      </template>

      <template
        v-for="user in gradeUsersList"
        :key="user.id"
        #[`column-user-${user.id}`]="{ value, row }"
      >
        <div class="flex justify-center items-center">
          <MasterMultiPill
            :entries="value"
            @clicked="(entry_id) => pillClickedHandler(row.id, entry_id, user)"
          />
        </div>
      </template>
    </DataTable>
  </BaseCard>
</template>

<script lang="ts">
import { defineComponent, PropType, computed, ref, toRef } from 'vue'
import ROTA_ID from '@/constants/rota'
import moment from 'moment'
import groupBy from 'lodash/groupBy'
import pick from 'lodash/pick'

// Constants
import { subGradeMap } from '@/constants/entries'

// Hooks
import useGradeUsers from '@/hooks/useGradeUsers'

// Types
import { RosterDate, Entry, DateBasics, EntryStatus } from '@/types/roster'
import { UserBasics } from '@/types/users'

// Components
import MasterMultiPill from '@/components/rota/master/MasterMultiPill.vue'

export default defineComponent({
  components: {
    MasterMultiPill,
  },

  props: {
    gradeRota: {
      type: Array as PropType<RosterDate<Entry>[]>,
      required: true,
    },
    gradeId: {
      type: Number,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
    readonly: {
      type: Boolean,
      required: true,
    },
    selectedRowId: {
      type: Number,
      default: 0,
    },
  },

  emits: ['selected', 'create-entry', 'manage-entry'],

  setup (props, ctx) {
    const rotaId = ref(ROTA_ID)
    const gradeIdRef = toRef(props, 'gradeId')

    const {
      loadingUsers: loadingUserList,
      users: gradeUsersList,
    } = useGradeUsers(rotaId, gradeIdRef)

    const loadingTable = computed(() => props.loading || loadingUserList.value)

    const invalidTableData = computed(() => {
      return props.loading 
        || !props.gradeRota.length 
        || loadingUserList.value 
        || !gradeUsersList.value.length
    })
    
    const rows = computed(() => {
      if (invalidTableData.value) return []

      return props.gradeRota.map(rotaDate => {

        // group entries by user id.
        const groupedEntries = groupBy(rotaDate.entries, (entry: Entry) => entry.user_id)

        interface RowItemDate {
          id: number;
          day: string;
          isHoliday: boolean;
          date: string;
        }

        interface RowItemEntries extends RowItemDate {
          [key: string]: Entry[] | string | number | boolean;
        }

        const rowObj: RowItemEntries = { // update typing.
          id: rotaDate.id,
          day: rotaDate.day,
          isHoliday: rotaDate.is_holiday,
          date: moment(rotaDate.date).format('DD/MM/YY'),
        }
        // loop through gradeUsersList to create individual cell data.
        gradeUsersList.value.forEach(user => {
          const key = `user-${user.id}`
          const value = groupedEntries[user.id] ?? [] // access entries by user_id after grouped.

          rowObj[key] = value
        })

        return rowObj
      })
    })

    const columns = computed(() => {
      if (invalidTableData.value) return [
        { name: 'date', label: 'Date', align: 'center', extendClasses: 'w-32' },
      ]

      const userColumns = gradeUsersList.value.map(user => ({
        name: `user-${user.id}`,
        label: user.name,
        align: 'center',
        textless: true,
        textlessHeading: true,
        extendClasses: 'w-32',
      }))

      return [
        { name: 'date', label: 'Date', align: 'center', extendClasses: 'w-32', stickyOnScroll: true },
        ...userColumns,
      ]
    })

    const rowClickedHandler = (rowId: number) => {
      if (props.readonly) return

      ctx.emit('selected', rowId)
    }

    const pillClickedHandler = (dateId: number, entryId: number | null, user: UserBasics) => {
      if (props.readonly) return
      
      const rotaDate = props.gradeRota.filter(d => d.id === dateId)[0] ?? null
      if (!rotaDate) return

      const existingEntries = rotaDate.entries.filter(e => e.user_id === user.id)
      const date = pick(rotaDate, ['id', 'date', 'day_id', 'day']) as DateBasics

      // create new entry as draft - No existing.
      if (entryId === null && existingEntries.length < 1) {
        ctx.emit('create-entry', date, user)
        return
      }

      // create new entry as draft - with existing 'deleting'
      if (entryId === null && existingEntries.length === 1) {
        ctx.emit('create-entry', date, user, existingEntries[0])
        return
      }

      // single entries - action based on entry.EntryStatus
      if (existingEntries.length === 1) {
        const selected = existingEntries[0]

        switch (selected.status) {
          case EntryStatus.DRAFT:
            ctx.emit('manage-entry', date, user, 'approving', selected)
            return
          case EntryStatus.DELETING:
            ctx.emit('manage-entry', date, user, 'deleting', selected)
            return
          case EntryStatus.APPROVED:
            ctx.emit('manage-entry', date, user, 'replacing-entry', selected)
            return
        }
      }
        
      // multi entries - action based on entry.EntryStatus
      if (existingEntries.length > 1) {
        const selected = 
          existingEntries[0].entry_id === entryId 
            ? existingEntries[0] 
            : existingEntries[1]
        const other = 
          existingEntries[0].entry_id === entryId 
            ? existingEntries[1] 
            : existingEntries[0]

        switch (selected.status) {
          case EntryStatus.DRAFT:
            ctx.emit('manage-entry', date, user, 'approving', selected, other)
            return
          case EntryStatus.DELETING:
            ctx.emit('manage-entry', date, user, 'deleting', selected, other)
            return
        }
      }
    }

    return {
      columns,
      rows,
      loadingTable,
      loadingUserList,
      gradeUsersList,
      rowClickedHandler,
      pillClickedHandler,
      subGradeMap,
    }
  },
})
</script>
